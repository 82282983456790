import {BasePage} from "../components/layout/BasePage";
import {Button, Col, Form, Input, message, Modal, Radio, Row, Select, Space} from "antd";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import {DynamicTable} from "../components/DynamicTable";
import {formatDate} from "../formatter";
import {useBoolean} from "../hooks/useBool";
import React, {useCallback, useEffect, useState} from "react";
import {t} from "../Messages";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import {useUser} from "../AuthContext";
import {CampaignDTable} from "./CampaignPage";
import {useFilter} from "../FilterContext";

export interface AdminDTable {
    id: number;
    username: string;
    name: string;
    last_login: string;
    campaign_id: number;
    role: string;
    campaign: {
        id: number;
        name: string;
    }
}

export function AdminsPage(props: {
    campaignId?: string
}) {
    return <BasePage>
        <Row gutter={[24, 24]}>
            <Col xs={24}>
                <AdminTable campaign={props.campaignId ? parseInt(props.campaignId) : undefined}/>
            </Col>
        </Row>
    </BasePage>
}

export function AdminTable(props: {
    campaign?: number
}) {

    const isWithCampaign = !!props.campaign;
    const data = usePaginatedTable<AdminDTable>('admins', {
        filter: {
            campaign_id: props.campaign ? props.campaign : undefined
        }
    });
    const {filter} = useFilter();
    useEffect(() => {
        data.updateFilters({
            campaign_id: props.campaign || filter.campaign
        })
        // eslint-disable-next-line
    }, [filter, props.campaign]);

    const modalAdd = useBoolean();
    const [toReset, setToReset] = useState<AdminDTable>();
    const [toEdit, setToEdit] = useState<AdminDTable>();

    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<AdminDTable>
            title={isWithCampaign ? 'Usuarios de campaña' : 'Datos de usuarios de backoffice'}
            table={data}
            rowKey="id"
            backTo={props.campaign ? `/campaigns` : undefined}
            extraButtons={<Button type="primary" onClick={modalAdd.setTrue}>Nuevo</Button>}
            columns={[
                {dataIndex: 'name', title: 'Nombre'},
                {dataIndex: 'username', title: 'Usuario'},
                {dataIndex: 'last_login', title: 'Ultimo login', align: 'left', render: formatDate},
                {dataIndex: 'campaign', title: 'Campaña', align: 'right', render: v => v.name},
                {
                    dataIndex: 'role', title: 'Rol', align: 'right', render: v => t(v)
                },
                {
                    dataIndex: 'id', title: '', render: (_, r) => <>
                        <Space>
                            <Button onClick={() => setToReset(r)}>Resetear clave</Button>
                            {r.id >= 0 && <Button onClick={() => {
                                modalAdd.setTrue();
                                setToEdit(r);
                            }}>Editar</Button>}
                        </Space>
                    </>
                },
            ]}/>
        <CreateEditAdminModal
            visible={modalAdd.value}
            prevData={toEdit}
            onCancel={() => {
                setToEdit(undefined);
                modalAdd.setFalse()
            }}
            onComplete={() => {
                data.refresh();
                setToEdit(undefined)
                modalAdd.setFalse()
            }}/>
        <ResetAdminModal admin={toReset}
                         onComplete={() => setToReset(undefined)} onCancel={() => setToReset(undefined)}/>
    </Row>
}

/**
 * A modal that can edit and update backofficers
 */
function CreateEditAdminModal({visible, onComplete, onCancel, prevData}:
                                  {
                                      visible: boolean,
                                      prevData?: AdminDTable,
                                      onComplete: () => void,
                                      onCancel: () => void,
                                  }) {

    const [form] = Form.useForm();
    const working = useBoolean(false);
    const {user, api} = useUser();
    const data = usePaginatedTable<CampaignDTable>('campaigns', {}, true);

    const allowAdmin = user.role === 'ADMIN';
    const askCampaign = !user.campaign.id;

    useEffect(() => {
        if (prevData) {
            form.setFieldsValue({
                name: prevData.name,
                username: prevData.username,
                role: prevData.role,
                campaign: prevData.campaign_id
            })
        }
    }, [prevData, form])

    const handleSubmit = useCallback(() => {
        working.setTrue();
        form.validateFields(['name', 'username', 'password', 'password2', 'role', 'campaign']).then(store => {
            if (prevData && store.password !== store.password2) {
                message.warn({content: "Las contraseñas no son iguales", key: 'add-admin', duration: 10});
                working.setFalse();
                return;
            }
            const data = {
                name: store.name,
                userName: store.username,
                pass: store.password,
                role: store.role,
                campaign: store.campaign
            };
            const promise = prevData
                ? api.updateAdmin(prevData.id, data)
                : api.addAdmin(data)
            promise.then(() => {
                message.success({
                    content: `Usuario '${store.name}' guardado`,
                    key: 'add-admin',
                    duration: 5
                });
                onComplete();
            }).catch(e => {
                console.error(e);
                message.error({content: 'Error al guardar usuario', key: 'add-admin', duration: 5});
            }).finally(working.setFalse)
        });
    }, [form, onComplete, api, working, prevData])

    return <Modal visible={visible}
                  onOk={form.submit}
                  okButtonProps={{loading: working.value}}
                  cancelButtonProps={{loading: working.value}}
                  okText={working.value ? t('generic.saving') : t(prevData ? 'generic.edit' : 'generic.add')}
                  title={!prevData ? 'Agregar administrador' : 'Editando administrador'}
                  onCancel={onCancel}>
        <Form onFinish={handleSubmit}
              layout="vertical"
              form={form}>
            <Form.Item name="name"
                       label={t('add_admin.name')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    disabled={working.value}
                />
            </Form.Item>
            <Form.Item name="username"
                       label={t('add_admin.username')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    disabled={working.value}
                />
            </Form.Item>
            {!prevData && <Form.Item name="password"
                                     label={t('add_admin.password1')}
                                     rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    type="password"
                    disabled={working.value}
                />
            </Form.Item>}
            {!prevData && <Form.Item name="password2"
                                     label={t('add_admin.password2')}
                                     rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    type="password"
                    disabled={working.value}
                />
            </Form.Item>}

            <Form.Item name="role"
                       label={t('add_admin.role')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Radio.Group>
                    {allowAdmin && <Radio.Button value="ADMIN">{t('ADMIN')}</Radio.Button>}
                    <Radio.Button value="MANAGER">{t('MANAGER')}</Radio.Button>
                    <Radio.Button value="SUPERVISOR">{t('SUPERVISOR')}</Radio.Button>
                </Radio.Group>
            </Form.Item>

            {askCampaign && <Form.Item name="campaign"
                                       label={t('add_admin.campaign')}
                                       rules={[{required: true, message: t('generic.required')}]}>
                <Select style={{width: '100%'}}>
                    {data.rows.map(c => <Select.Option value={c.campaign_id}
                                                       key={c.campaign_id}>{c.name}</Select.Option>)}
                </Select>
            </Form.Item>}
        </Form>
    </Modal>
}

function ResetAdminModal({admin, onComplete, onCancel}:
                             { admin?: AdminDTable, onComplete: () => void, onCancel: () => void }) {

    const [form] = Form.useForm();
    const working = useBoolean(false);
    const {api} = useUser();

    const handleSubmit = useCallback(() => {
        working.setTrue();
        form.validateFields(['password', 'password2']).then(store => {
            if (!admin || store.password !== store.password2) {
                message.warn({content: "Las contraseñas no son iguales", key: 'reset-pin-admin', duration: 10});
                working.setFalse();
                return;
            }
            api.resetAdminPass(admin.id, {newPass: store.password})
                .then(() => {
                    message.success({
                        content: `Clave de '${admin.name}' guardada`,
                        key: 'reset-pin-admin',
                        duration: 5
                    })
                    onComplete();
                })
                .catch(e => {
                    console.error(e);
                    message.error({content: 'Error al cambiar clave de usuario', key: 'reset-pin-admin', duration: 5});
                })
                .finally(working.setFalse)
        });
    }, [form, onComplete, api, working, admin]);

    return <Modal visible={!!admin}
                  onOk={form.submit}
                  okButtonProps={{loading: working.value}}
                  cancelButtonProps={{loading: working.value}}
                  okText={working.value ? t('generic.saving') : t('generic.ok')}
                  title={`Cambiar clave de usuario '${admin?.username}'`}
                  onCancel={onCancel}>
        <Form onFinish={handleSubmit}
              layout="vertical"
              form={form}>
            <Form.Item name="password"
                       label={t('add_admin.password1')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    type="password"
                    disabled={working.value}
                />
            </Form.Item>
            <Form.Item name="password2"
                       label={t('add_admin.password2')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    type="password"
                    disabled={working.value}
                />
            </Form.Item>
        </Form>
    </Modal>
}
