import './Sidebar.less';

export function Sidebar(props: React.PropsWithChildren<{
    isOpen: boolean,
    withBorder?: boolean
}>) {

    const contentClass = props.isOpen ? "content open" : "content";
    const sideClass = props.withBorder === undefined || props.withBorder
        ? 'side-bar with-border'
        : 'side-bar';

    return <div className={sideClass}>
        <Bar isOpen={props.isOpen}>
            Sidebar
        </Bar>
        <div className={contentClass}>
            {props.children}
        </div>
    </div>
}

function Bar(props: React.PropsWithChildren<{
    isOpen: boolean
}>) {
    var sidebarClass = props.isOpen ? "sidebar open" : "sidebar";
    return (
        <div className={sidebarClass}>
            <div>I have slided</div>
        </div>
    );
}
