import {BasePage} from "../components/layout/BasePage";
import {AddGroupMobilePage} from "./mobile/AddGroupMobilePage";
import './GroupCreationPage.less';

export function GroupCreationPage() {
    return <BasePage>
        <div className="group-creation-page" style={{
        }}>
            <AddGroupMobilePage/>
        </div>
    </BasePage>
}

