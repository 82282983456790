let BASE_URL = "https://www.kakuaa.io/api/backoffice"
let GOOGLE_API_KEY = "AIzaSyDSmKWda24CKFDK1vfJdjnrBdvCCX6thVA"
const w: any = window;

if (w.__env) {
    BASE_URL = w.__env.BASE_URL;
    GOOGLE_API_KEY = w.__env.GOOGLE_API_KEY
}

const CONFIG = {
    apiUrl: BASE_URL,
    googleApiKey: GOOGLE_API_KEY
}

export default CONFIG;
