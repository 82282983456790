import dayjs from "dayjs";

export function formatDate(date: unknown) {

    if (date instanceof Date) {
        return date.toString();
    }

    if (date == null) return '';

    if (typeof date === 'string') {

        if (date.length === 19) {
            return dayjs(date).format('YYYY-MM-DD');
        }

        if (date.length === 26 || date.length === 25 || date.length === 24) {
            return dayjs(date).format('YYYY-MM-DD');
        }
    }

    console.log('Trying to format unknown type: ', date, typeof date, `${date}`.length);
}

export function formatDateTime(date: unknown) {

    if (date instanceof Date) {
        return date.toString();
    }

    if (typeof date === 'string') {

        if (date.length === 19) {
            return dayjs(date).format('YYYY-MM-DD HH:mm');
        }

        if (date.length === 26 || date.length === 25) {
            return dayjs(date).format('YYYY-MM-DD HH:mm');
        }
    }

    console.log('Trying to format unknown type: ', date, typeof date, `${date}`.length);
}

export function age(date: unknown) {

    if (typeof date === 'string')
        return dayjs().diff(dayjs(date), 'year', false);

    console.log('Trying to get age from unknown type: ', date, typeof date, `${date}`.length);
}

export function formatMoney(value: any, symbol?: string, decimalDigits?: number) {
    if (!value && value !== 0) return '';
    if (typeof value === 'string' && value.startsWith('Gs')) return value;

    const amount = decimalDigits ? value : Math.round(value);
    const formattedAmount = new Intl.NumberFormat('it', {
        minimumFractionDigits: decimalDigits || 0,
        maximumFractionDigits: decimalDigits || 0
    }).format(amount);
    const prefix = symbol !== undefined ? `${symbol} ` : '';
    return `${prefix}${formattedAmount}`;
}

export function money(value: any) {
    return formatMoney(value, 'Gs ', 0) || '0 Gs.';
}

export function amount(value: any) {
    return formatMoney(value, undefined, 0);
}

export function miliSecondsToDate(miliseconds?: number) {
    if (miliseconds === undefined) return '';
    let asNumber = miliseconds;
    if (isNaN(asNumber)) {
        return '';
    }
    asNumber = Math.ceil(asNumber / 1000);
    const finalSeconds = asNumber % 60;
    const finalMinutes = ((asNumber - finalSeconds) % 3600) / 60;
    const finalHours = parseInt(`${asNumber / 3600}`, 10);

    const pad = (num: number) => num.toString().padStart(2, '0');

    return `${pad(finalHours)}:${pad(finalMinutes)}:${pad(finalSeconds)}`;
}
