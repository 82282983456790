import {Button, Col, DatePicker, Row} from "antd";
import Select from "react-select";
import AsyncSelect from 'react-select/async';
import './Filter.less';
import {Cities, CityType} from "../../util/GeoData";
import {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Filters, usePaginatedTable} from "../../hooks/usePaginatedTable";
import {CampaignDTable} from "../../pages/CampaignPage";
import {useUser} from "../../AuthContext";
import {DTEndUser} from "../../pages/EndUsersPage";
import {SearchOutlined} from '@ant-design/icons';
import {RangeValue} from "rc-picker/lib/interface";
import {Moment} from 'moment';
import {FilterContext} from "../../FilterContext";
import {GroupSearchDT} from "../../Model";

type OptionType<T> = { value: T, label: string };


const NO_CAMPAIGN = -100;
const NO_GROUP = -100;
const NO_USER = -100;
const NO_CITY = -100;
const NO_DEPARTMENT = 'TODOS';
const ALL_CITIES: OptionType<number> = {value: NO_CITY, label: 'TODAS'}

export function Filter() {

    const {user} = useUser();
    const filterContext = useContext(FilterContext);

    const askCampaign = user.role === 'ADMIN';

    const [campaign, setCampaign] = useState<number>(askCampaign && user.campaign.id ? user.campaign.id : NO_CAMPAIGN);
    const [city, setCity] = useState<number>(NO_CITY);
    const [department, setDepartment] = useState<string>(NO_DEPARTMENT);
    const [group, setGroup] = useState<number>(NO_GROUP);
    const [endUser, setEndUser] = useState<number>(NO_USER);
    const [dateRange, setDateRange] = useState<RangeValue<Moment>>([null, null]);

    function setFilters() {
        filterContext.setFilter({
            city: city === NO_CITY ? undefined : Cities.find(c => c.id === city)?.city,
            department: department === NO_DEPARTMENT ? undefined : department,
            campaign: campaign === NO_CAMPAIGN ? undefined : campaign,
            date: dateRange && dateRange.filter(f => f !== null).length === 2 ? {
                start: dateRange[0]!.format('YYYY-MM-DD'),
                end: dateRange[1]!.format('YYYY-MM-DD'),
            } : undefined,
            endUser: endUser === NO_USER ? undefined : endUser,
            group: group === NO_GROUP ? undefined : group
        });
    }

    return <Row className="main-filter" gutter={[0, 8]} align="middle" justify="center">
        {askCampaign && <CampaignFilter setCampaign={setCampaign}/>}
        <GroupFilter setGroup={setGroup} campaign={campaign}/>
        <EndUserFilter setEndUser={setEndUser}/>
        <Col xs={12} md={6}>
            Fecha:
            <br/>
            <DatePicker.RangePicker className="range-picker"
                                    value={dateRange}
                                    onChange={dr => setDateRange(dr)}/>
        </Col>
        <GeoFilter city={city} department={department} setCity={setCity} setDepartment={setDepartment}/>
        <Col xs={12} md={6} className="apply-cell">
            <span> </span><br/>
            <Button className="apply" icon={<SearchOutlined/>} type="primary" onClick={setFilters}>
                Aplicar filtros
            </Button>
        </Col>
    </Row>

}

function CampaignFilter(props: {
    setCampaign: (c: number) => void
}) {

    const remoteCampaigns = usePaginatedTable<CampaignDTable>('campaigns', {}, true);
    const campaigns = [{value: NO_CAMPAIGN, label: 'TODAS'}, ...remoteCampaigns.rows.map(c => ({
        value: c.campaign_id,
        label: c.name
    }))];

    return <Col xs={12} md={6}>
        Campaña:
        {remoteCampaigns.isLoading
            ? 'Cargando...'
            :
            <Select options={campaigns} className="select" onChange={v => props.setCampaign(v?.value || NO_CAMPAIGN)}/>
        }
    </Col>
}

function GroupFilter(props: {
    setGroup: (g: number) => void,
    campaign: number
}) {

    const {api} = useUser();
    const [defGroups, setDefGroups] = useState<OptionType<number>[]>([]);

    useEffect(() => {
        api.dynamicTable<GroupSearchDT>('group_search', {
            params: {campaign_id: props.campaign},
            limit: 20,
            offset: 0,
            order: {name: true}
        }).then(d => setDefGroups(prepend(
            {label: 'Todos', value: NO_GROUP},
            d.rows.map(sg => ({label: `${sg.name} (${sg.cycle})`, value: sg.root_id})))
        ));
    }, [props.campaign, api]);

    const promiseOptions = useCallback(inputValue => {
        const filter: Partial<GroupSearchDT> = {
            name: inputValue,
            campaign_id: props.campaign === NO_CAMPAIGN ? undefined : props.campaign
        }

        return api.dynamicTable<GroupSearchDT>('group_search', {
            params: filter,
            limit: 20,
            offset: 0,
            order: {name: true}
        }).then(d => prepend(
            {label: 'Todos', value: NO_GROUP},
            d.rows.map(sg => ({label: `${sg.name} (${sg.cycle})`, value: sg.root_id})))
        );
    }, [api, props.campaign]);

    return <Col xs={12} md={6}>
        Grupo:
        <AsyncSelect loadOptions={promiseOptions} className="select"
                     defaultOptions={defGroups}
                     onChange={v => props.setGroup(v?.value || NO_GROUP)}/>
    </Col>
}

function EndUserFilter(props: {
    setEndUser: (eu: number) => void
}) {

    const {api} = useUser();

    const promiseOptions = useCallback(inputValue => {
        const filter: Filters<DTEndUser> = {}
        if (isNaN(parseInt(inputValue))) {
            filter.full_name = inputValue;
        } else {
            filter.document = inputValue;
        }

        return api.dynamicTable<DTEndUser>('end_users', {
            params: filter,
            limit: 20,
            offset: 0,
            order: {name: true}
        }).then(d => prepend(
            {value: NO_USER, label: 'Todos'},
            d.rows.map(eu => ({label: `${eu.name} ${eu.last_name} - ${eu.document}`, value: eu.id})))
        );
    }, [api]);

    return <Col xs={12} md={6}>
        Usuario:
        <AsyncSelect loadOptions={promiseOptions} className="select"
                     defaultOptions={true}
                     onChange={v => props.setEndUser(v?.value || NO_USER)}/>
    </Col>
}

function GeoFilter({department, city, setCity, setDepartment}: {
    city: number,
    department: string,
    setCity: (val: number) => void,
    setDepartment: (val: string) => void
}) {

    const departments = useMemo(() => {
        const deps = Array.from(new Set(Cities.map(c => c.department)));
        return [NO_DEPARTMENT, ...deps].map(d => ({value: d, label: d}));
    }, []);

    const cities = useMemo(() => {
        return [
            ALL_CITIES,
            ...Cities
                .filter(c => department === 'TODOS' || department === c.department)
                .map(d => ({value: d.id, label: d.city}))
        ];
    }, [department]);

    const selectedCity: OptionType<number> = city === NO_CITY
        ? ALL_CITIES
        : mapToOption(Cities.find(c => c.id === city));

    return <>
        <Col xs={12} md={6}>
            Departamento:
            <Select options={departments}
                    value={department ? {value: department, label: department} : undefined}
                    className="select"
                    onChange={v => {
                        setDepartment(v ? v.value : 'TODOS');
                        setCity(NO_CITY);
                    }}/>
        </Col>
        <Col xs={12} md={6}>
            Ciudad:
            <Select options={cities}
                    className="select"
                    value={selectedCity}
                    onChange={v => {
                        if (v) {
                            const selected = Cities.filter(c => c.id === v.value).pop();
                            if (selected) {
                                setDepartment(selected.department);
                                setCity(selected.id);
                                return;
                            }
                            if (!selected && v.value === ALL_CITIES.value) {
                                setCity(ALL_CITIES.value);
                                return;
                            }
                        }
                        setDepartment(NO_DEPARTMENT);
                        setCity(NO_CITY);
                    }}/>
        </Col>
    </>
}


function mapToOption(find?: CityType): OptionType<number> {
    if (!find) return ALL_CITIES;
    return {value: find.id, label: find.city};
}

function prepend<T>(toPrepend: T, arr: T[]): T[] {
    return [toPrepend, ...arr];
}
