import {Button, InputNumber, Layout, message, Modal, Space} from "antd";
import {useUser} from "../AuthContext";
import './MobilePage.less';
import React, {useState} from "react";
import {ApiError} from "../Api";
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from "react-router-dom";
import {AddGroupMobilePage} from "./mobile/AddGroupMobilePage";
import logoWhite from '../assets/logo-white.svg';

export function MobilePage() {

    const {logout} = useUser()

    return <Layout className="mobile-page">
        <Layout.Header>
            <div className="header">
                <img src={logoWhite} alt="logo" className="logo"/>
                <h1 className="app-name">KAKUAA</h1>
                <Button onClick={logout}>Salir</Button>
            </div>
        </Layout.Header>

        <Router>
            <Switch>

                <Route path="/" exact>
                    <DashboardPage/>
                </Route>
                <Route path="/group/add" exact>
                    <AddGroupMobilePage/>
                </Route>
                <Route>
                    <Redirect to="/"/>
                </Route>
            </Switch>
        </Router>
    </Layout>
}


function DashboardPage() {

    const {api, user} = useUser();
    const [resetVisible, setResetVisible] = useState(false);
    const [working, setWorking] = useState(false);


    function resetPIN(document: number) {
        setWorking(true);
        api.resetPin(document)
            .then(r => {
                setWorking(false);
                setResetVisible(false);
                message.success({content: `PIN de ${r.name} reseteado`, key: 'reset-pin', duration: 5});
            })
            .catch(e => {
                setWorking(false);
                if (e instanceof ApiError && e.asSimpleCode() === 404) {
                    message.info({content: 'Usuario no encontrado', key: 'reset-pin', duration: 5});
                    return
                }
                message.error({content: 'Error al resetear PIN', key: 'reset-pin', duration: 5});
            })
    }


    return <>
        <Layout.Content className="content">
            <div className="welcome">
                Bienvenido/a {user.name}, estas en modo móvil. En este modo sólo puedes resetear el pin y crear grupos.
            </div>
            <Space direction="vertical">
                <Link to="/group/add">
                    <Button type="primary" className="mobile-button">
                        Agregar grupo</Button>
                </Link>
                <Button type="primary" className="mobile-button" onClick={() => setResetVisible(true)}>
                    Resetear PIN
                </Button>
            </Space>
        </Layout.Content>
        <ResetPinModal isOpen={resetVisible}
                       onCancel={() => setResetVisible(false)}
                       working={working}
                       onReset={resetPIN}
        />
    </>
}

function ResetPinModal(props: {
    isOpen: boolean;
    onCancel: () => void;
    working: boolean;
    onReset: (document: number) => void;
}) {

    const [doc, setDoc] = useState<number>();

    function onOk() {
        if (!doc) {
            message.warn({content: 'Ingrese una cédula', duration: 5, key: 'reset-pin'});
            return;
        }
        props.onReset(doc);
    }

    return <Modal visible={props.isOpen}
                  onCancel={props.onCancel}
                  onOk={onOk}
                  closable={false}
                  cancelButtonProps={{disabled: props.working}}
                  cancelText="Cancelar"
                  okText={props.working ? "Reseteando" : "Resetear"}
                  okButtonProps={{danger: true, loading: props.working}}
    >
        <h1>Ingrese la cédula del usuario a resetear</h1>

        <InputNumber<number>
            style={{width: '100%'}}
            placeholder="4787587"
            onChange={setDoc}
        />

    </Modal>
}
