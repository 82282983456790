import React, {useContext, useMemo, useState} from "react";

export interface FilterData {
    group?: number;
    endUser?: number;
    department?: string;
    city?: string;
    date?: {
        start: string;
        end: string;
    };
    campaign?: number;
}

const DEF_DATA: FilterData = {};

export const FilterContext = React.createContext({
    filter: DEF_DATA,
    setFilter: (gt: FilterData) => {
    }
});

export function FilterProvider(props: {
    children: React.ReactNode;
}) {

    const [filter, setFilter] = useState<FilterData>(DEF_DATA);

    const value = useMemo(() => ({
        filter,
        setFilter
    }), [filter]);

    return <FilterContext.Provider value={value}>
        {props.children}
    </FilterContext.Provider>
}

export function useFilter() {
    const {filter} = useContext(FilterContext);
    return {filter};
}
