export interface LoginResponse {
    username: string;
    name: string;
    token: string;
    role: 'ADMIN' | 'MANAGER' | 'SUPERVISOR';
    campaign: {
        name: string,
        id: number | null
    },
    version: 4
}

export interface ResetResponse {
    name: string;
}


export interface AddAdminRequest {
    name: string;
    userName: string;
    pass: string;
    role: LoginResponse['role'];
    campaign: number;
}

export interface ResetAdminPass {
    newPass: string
}

export interface UpdateEndUser {
    name: string;
    lastName: string;
    document: string;
    gender: string;
    birthDate: string;
    phone: string
}

export interface ApiGroup {
    id: string;
    name: string;

    every: number;

    lat: string;
    lng: string;

    actionPrice: number;

    location: string;
    city: string;
    department: string;
}

export interface AddGroupData {
    name: string;
    every: number;

    lat: number;
    lng: number;

    actionPrice: number;
    location: string;
    city: string;
    department: string;

    meetingDay: string;
    meetingHour: string;

    sharedFoundAmount: number;
    maxShares: number;

    members: Array<{ name: string, document: string, lastName: string, birthDate: string, gender: string }>
    penalties: Array<{ name: string, amount: string }>

    supervisor: number;
    campaign: number;
    endDate: string;
}

export interface InvalidRequest {
    msg: string;
    detail: Record<string, string[]>;
}

export type ReportRequestParam = string | number | undefined | { start: string, end: string };
export type ReportRequest = Record<string, Record<string, ReportRequestParam>>;


export interface ReportResponse {
    name: string;
    data: Record<string, ReportResponsePart>
}

export interface SingleReportData {
    title: string;
    data: Async<Datum[]>;
}

export interface ReportResponsePart {
    data: Datum[],
    params: Record<string, string>
}

export interface Datum {
    x: number;
    x2: number;
    y: string;
    count?: number;
}

export interface TableRequest {
    params?: Record<string, ReportRequestParam>;
    limit?: number;
    offset?: number;
    order?: Record<string, boolean>;
}

export interface TableResponse<T> {
    rows: Array<T>;
    count: number;
}

export type Async<T, E = Error> = {
    state: 'NO_REQUESTED'
} | {
    state: 'FETCHING'
} | {
    state: 'LOADED',
    data: T
} | {
    state: 'ERROR',
    error: E
}

/**
 * A single helper to produce NetworkResource instances quickly
 */
export const AsyncHelper = {
    noRequested: () => ({state: 'NO_REQUESTED' as const}),
    fetching: () => ({state: 'FETCHING' as const}),
    loaded: <T>(data: T) => ({state: 'LOADED' as const, data}),
    error: <E>(error: E) => ({state: 'ERROR' as const, error}),

    or: function <T, E>(nr: Async<T, E>, def: T) {
        if (nr.state === 'LOADED') return nr.data;
        return def;
    },

    map: function <T, E, K>(nr: Async<T, E>, mapper: (toMap: T) => K): Async<K, E> {
        switch (nr.state) {
            case 'ERROR':
                return AsyncHelper.error(nr.error);
            case 'FETCHING':
                return AsyncHelper.fetching();
            case 'LOADED':
                const mapped: K = mapper(nr.data);
                return AsyncHelper.loaded<K>(mapped);
            case 'NO_REQUESTED':
            default:
                return AsyncHelper.noRequested();

        }
    }
};


export interface GroupSearchDT {
    name: string;
    root_id: number;
    cycle: number;
    campaign_id: number;
}
