import {BasePage} from "../components/layout/BasePage";
import {Button, Col, message, Modal, Row, Space, Tabs} from "antd";
import React, {useState} from "react";
import {LoginResponse, ReportRequest} from "../Model";
import {useApi} from "../useApi";
import {extractReport} from "./DashboardPage";
import {PieReport} from "../components/report/PieReport";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import {DynamicTable} from "../components/DynamicTable";
import {age, amount, formatDate, formatDateTime, money} from "../formatter";
import {role, t} from "../Messages";
import {useUser} from "../AuthContext";
import {Api, ApiError} from "../Api";
import {AjaxImage} from "../components/AjaxImage";
import {useAsync} from "../hooks/useAsync";
import {UploadImage} from "./mobile/AddGroupMobilePage";
import {GroupDTable} from "./GroupsPage";
import {Link} from "react-router-dom";
import {BarReport} from "../components/report/BarReport";
import {ArrowLeftOutlined} from "@ant-design/icons";

export function GroupPage(props: {
    id: string
}) {

    const {api, user: {role}} = useUser();
    const group = useAsync(
        (id) => api
            .dynamicTable<GroupDTable>('groups', {params: {group_id: id}})
            .then(d => {
                if (d.rows.length > 0) return d.rows[0];
                else throw new ApiError('group.not.found', 404, '');
            }),
        [parseInt(props.id)],
        [api]
    );

    const [imageModel, setImageModal] = useState<string>();

    function showRules() {
        setImageModal(Api.getGroupImageUrl(props.id));
    }

    function onNewImage() {
        if (group.state !== 'LOADED') return;
        group.replace({image_id: props.id});
    }

    const hasImage = group.state === 'LOADED' && !!group.data.image_id;
    const name = group.state === 'LOADED' ? group.data.name : "Datos del comité";

    return <BasePage>
        <Row gutter={[24, 24]}>
            {role !== 'SUPERVISOR' && <Col xs={24}>
                <GroupDashboard group={props.id}/>
            </Col>}
            <Col xs={24} style={{background: 'white'}}>

                <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
                    <Link to="/groups">
                        <ArrowLeftOutlined/>
                    </Link>

                    <div style={{flex: 10}}>
                        <h4>{name}</h4>
                    </div>

                    <div>
                        <Space>
                            <Button onClick={() => showRules()}>Ver reglamento</Button>
                        </Space>
                    </div>
                </div>

                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Miembros" key="1">
                        <MemberTable group={props.id}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Sesiones" key="2">
                        <MeetingTable group={props.id} role={role}/>
                    </Tabs.TabPane>
                </Tabs>
            </Col>
        </Row>

        <Modal visible={!!imageModel}
               okButtonProps={{style: {display: 'none'}}}
               cancelText="Aceptar"
               onCancel={() => setImageModal(undefined)}>
            {hasImage && <AjaxImage src={imageModel || ""} alt={`Reglamento del grupo ${name}`}/>}
            {!hasImage && <div>
                <div>
                    El grupo no tiene imagen de reglamento, puedes cargar una aquí.
                </div>
                <UploadImage groupId={props.id} onSuccess={onNewImage}/>
            </div>}
        </Modal>
    </BasePage>
}

export function GroupDashboard({group}: { group: string }) {
    const [params] = useState<ReportRequest>({
        'report.by_group.location': {group},
        'report.by_group.age': {group},
        'report.by_group.gender': {group},
        'report.by_group.founds': {group},
    });
    const reports = useApi('report', 'BY_GROUP', params);
    const founds = extractReport('report.by_group.founds', reports);
    const ageReport = extractReport('report.by_group.age', reports);
    const gender = extractReport('report.by_group.gender', reports);

    return <Row gutter={[24, 24]} align="middle" justify="center">
        <Col>
            <div style={{background: 'white', padding: 10}}>
                <BarReport report={founds} translateX={true}/>
            </div>
        </Col>
        <Col>
            <div style={{background: 'white'}}>
                <BarReport report={ageReport}/>
            </div>
        </Col>
        <Col>
            <div style={{background: 'white', padding: 10}}>
                <PieReport report={gender}/>
            </div>
        </Col>
    </Row>
}

export interface DTMember {
    id: number;
    group_id: number;
    group_name: string;
    document: string;
    created_at: string;
    name: string;
    phone: string;
    last_name: string;
    number: number;
}

interface DTMeeting {
    id: number;
    date: string;
    group_id: number;
    group_name: string;
    extra_funds: number;
    shares_brought: number;
    shares_count: number;
    total_penalties: number;
    total_social: number;
    present: number;
    total: number;
}

export function MemberTable(props: { group: string }) {

    const data = usePaginatedTable<DTMember>('members', {
        filter: {group_id: parseInt(props.group)}
    }, true);
    const [working, setWorking] = useState(false);
    const {api} = useUser();

    function resetPIN(document: string) {
        setWorking(true);
        api.resetPin(parseInt(document))
            .then(r => {
                setWorking(false);
                message.success({content: `PIN de ${r.name} reseteado`, key: 'reset-pin', duration: 5});
            })
            .catch(e => {
                setWorking(false);
                if (e instanceof ApiError && e.asSimpleCode() === 404) {
                    message.info({content: 'Usuario no encontrado', key: 'reset-pin', duration: 5});
                    return
                }
                message.error({content: 'Error al resetear PIN', key: 'reset-pin', duration: 5});
            })
    }

    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<DTMember>
            withTopBorder={false}
            title=""
            table={data}
            extraButtons={<Space>
            </Space>}
            columns={[
                {
                    dataIndex: 'document', title: 'Cédula', render: (_, row) => {
                        return <Link to={`/users/${row.id}/`}>{row.document}</Link>
                    }
                },
                {dataIndex: 'created_at', title: 'Registro', render: formatDate},
                {dataIndex: 'name', title: 'Nombre', render: (_, r) => `${r.name} ${r.last_name || ''}`},
                {dataIndex: 'birth_date', title: 'Edad', render: age},
                {dataIndex: 'gender', title: 'Sexo', render: t},
                {dataIndex: 'city', title: 'Ubicación'},
                {dataIndex: 'phone', title: 'N de celular'},
                {dataIndex: 'number', title: 'Rol', render: role},
                {
                    dataIndex: 'id', title: '', render: (id, r) => <>
                        <Space>
                            <Button loading={working} danger onClick={() => resetPIN(r.document)}>Reset PIN</Button>
                        </Space>
                    </>
                },
            ]}/>
    </Row>
}

export function MeetingTable(props: { group: string, role: LoginResponse["role"] }) {

    const data = usePaginatedTable<DTMeeting>('meetings', {
        filter: {group_id: parseInt(props.group)}
    }, true);

    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<DTMeeting>
            withTopBorder={false}
            title=""
            table={data}
            columns={props.role === 'SUPERVISOR'
                ? [
                    {dataIndex: 'date', title: 'Fecha', render: formatDateTime},
                    {
                        dataIndex: 'present',
                        title: 'Asistencia',
                        align: 'right',
                        render: (_, r) => `${r.present}/${r.total}`
                    },
                    {
                        dataIndex: 'id', title: '', render: (id, r) => <>
                            <Space>
                                <Link to={`${props.group}/meetings/${r.id}/members`}>
                                    <Button>Ver participantes</Button>
                                </Link>
                            </Space>
                        </>
                    },
                ]
                : [
                    {dataIndex: 'date', title: 'Fecha', render: formatDateTime},
                    {
                        dataIndex: 'shares_count',
                        title: 'Acciones compradas',
                        align: 'right',
                        render: r => amount(r || 0)
                    },
                    {dataIndex: 'shares_brought', title: 'Total monto acciones', align: 'right', render: money},
                    {dataIndex: 'total_social', title: 'Fondos social', align: 'right', render: money},
                    {dataIndex: 'total_penalties', title: 'Multa', align: 'right', render: money},
                    {dataIndex: 'extra_funds', title: 'Fondos extra', align: 'right', render: money},
                    {
                        dataIndex: 'present',
                        title: 'Asistencia',
                        align: 'right',
                        render: (_, r) => `${r.present}/${r.total}`
                    },
                    {
                        dataIndex: 'id', title: '', render: (id, r) => <>
                            <Space>
                                <Link to={`${props.group}/meetings/${r.id}/members`}>
                                    <Button>Ver participantes</Button>
                                </Link>
                            </Space>
                        </>
                    },
                ]}/>
    </Row>
}
