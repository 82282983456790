import {DependencyList, useCallback, useEffect, useRef, useState} from "react";
import {Async, AsyncHelper} from "../Model";
import {ApiError} from "../Api";
import {useMountedState} from "react-use";

export declare type FunctionReturningPromise<P extends Parameters<any>, T> = (...args: P) => Promise<T>;

export function useAsync< // generic types
    RT extends any,
    P extends any[]>
(
    // params
    fn: (...args: P) => Promise<RT>,
    params: P = [] as any as P,
    deps: DependencyList = []
): UseAsyncReturnType<RT> {
    const [data, setData] = useState<Async<RT>>(AsyncHelper.noRequested())
    const lastCallId = useRef(0);
    const isMounted = useMountedState();

    const callback = useCallback((): Promise<RT> => {
        const callId = ++lastCallId.current;
        setData(AsyncHelper.fetching());
        return fn(...params).then(
            (value) => {
                isMounted() && callId === lastCallId.current && setData(AsyncHelper.loaded(value));
                return value;
            },
            (error) => {
                console.error(error);
                isMounted() && callId === lastCallId.current && setData(AsyncHelper.error(error));
                return error;
            }
        );
        // eslint-disable-next-line
    }, [...params, ...deps]);

    useEffect(() => {
        callback();
    }, [callback]);

    return {
        ...data,
        loaded: (t: RT) => setData(AsyncHelper.loaded(t)),
        fail: (t: ApiError) => setData(AsyncHelper.error(t)),
        loading: () => setData(AsyncHelper.fetching()),
        replace: (newVal: Partial<RT>) => {
            setData(old => {
                if (old.state === 'LOADED') {
                    const prev = old.data;
                    if (typeof prev === 'object') {
                        const o = prev as object;
                        return AsyncHelper.loaded({...o, ...newVal} as RT);
                    } else {
                        throw new Error(`Trying to replace a value that is not an object, use #.loaded`);
                    }
                } else return old;
            });
        },
        reload: () => callback()
    };
}

export type UseAsyncReturnType<T> = Async<T> & {
    loaded: (t: T) => void;
    fail: (t: ApiError) => void;
    loading: () => void;
    replace: (t: Partial<T>) => void;
    reload: () => void;
}

