import {Pie} from 'react-chartjs-2';
import {Datum, SingleReportData} from "../../Model";
import {useMemo} from "react";
import {t} from "../../Messages";
import {Spin} from "antd";

export function PieReport({report, translateX = true}: {
    report: SingleReportData,
    translateX?: boolean
}) {
    return <div style={{padding: 10}}>
        <h4>{t(`${report.title}.title`)}</h4>
        {report.data.state === 'LOADED'
            ? <LoadedPie data={report.data.data} name={report.title} translateX={translateX}/>
            : <Spin size="large"/>
        }
    </div>
}

function LoadedPie(props: { name: string, data: Datum[], translateX: boolean }) {

    const finalData = useMemo(() => mapToChart(props.name, props.data, props.translateX),
        [props.name, props.data, props.translateX]);

    if (props.data.map(d => d.x).reduce((d1, d2) => d1 + d2, 0) === 0) {
        return <div style={{color: 'darkgray'}}>Sin datos para el filtro seleccionado</div>
    }

    return <Pie data={finalData} type="pie"
                options={{
                    plugins: {
                        legend: {
                            position: 'bottom',
                            align: 'start'
                        }
                    }
                }}/>
}

export function mapToChart(key: string, data: Datum[], translateX: boolean) {

    const rows = data || [];
    const labels = rows.map(r => translateX ? t(`${key}.${r.y}`) : r.y);
    const values = rows.map(r => r.x);

    return {
        labels,
        datasets: [{
            label: t(`${key}.xLabel`),
            data: values,
            backgroundColor: [
                '#4CA759',
                '#B1D038',
                '#7F54E1',
                '#2866AD',
                '#2C3487',
                '#402A71',
                '#D83837',
                '#E79EC0',
                '#F9751A',
                '#FDD200'
            ],
            borderColor: [
                '#4CA759',
                '#B1D038',
                '#7F54E1',
                '#2866AD',
                '#2C3487',
                '#402A71',
                '#D83837',
                '#E79EC0',
                '#F9751A',
                '#FDD200'
            ],
            borderWidth: 1
        }]
    }
}
