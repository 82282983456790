import {JSONSchema7} from "json-schema";
import {AjvError, withTheme} from "@rjsf/core";
import {Theme as AntDTheme} from '@rjsf/antd';
import {TimeWidget} from "../react-schema-form/TimeWidget";
import {t} from "../../Messages";
import RadioWidget from "../react-schema-form/RadioWidget";
import dayjs from "dayjs";

const DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const SCHEMA: JSONSchema7 = {
    title: "Nuevo grupo",
    description: "Crear nuevo grupo de ahorro",
    type: "object",
    required: [
        "name",
        "periodicity",
        "actionPrice",
        "meetingDay",
        "meetingHour"
    ],
    properties: {
        name: {
            type: "string",
            title: "Nombre de grupo"
        },
        periodicity: {
            type: "number",
            title: "Periodicidad",
            description: "Cada cuantas semanas se reunirá el grupo",
            default: 2,
        },
        actionPrice: {
            type: "number",
            title: "Precio de acción",
            description: "Precio de una acción",
            default: 5000
        },
        maxShares: {
            type: "number",
            title: "Cantidad máxima de acciones por sesión",
            default: 5
        },
        sharedFoundAmount: {
            type: "number",
            title: "Fondo social por reunión",
            default: 2000,
            minimum: 0
        },
        meetingDay: {
            type: 'string',
            title: 'Día de reunión',
            enum: DAYS,
            enumNames: DAYS.map(d => t(d)),
            default: 'MONDAY'
        } as any,
        meetingHour: {
            type: "string",
            title: "Hora de reunión",
            description: "Hora de reunión, por ejemplo 18:00",
            default: "20:00"
        },
        endDate: {
            type: "string",
            title: "Fecha de repartición",
            description: "Fecha de cierre del grupo",
            format: 'date',
            default: dayjs().add(3, 'months').format("YYYY-MM-DD")
        },
        penalties: {
            type: 'array',
            title: 'Multas',
            minItems: 0,
            maxItems: 10,
            items: {
                type: 'object',
                title: '',
                properties: {
                    name: {type: 'string', title: 'Nombre', minimum: 0},
                    amount: {type: 'integer', title: 'Monto', minimum: 0}
                },
                required: ["name", "amount"]
            },
            default: [
                {"name": "Usar celular durante la reunión", "amount": 2000},
                {"name": "Llegada tardía", "amount": 5000},
            ]
        },

        members: {
            type: 'array',
            title: 'Miembros',
            minItems: 8,
            maxItems: 50,
            items: {
                type: 'object',
                title: '',
                properties: {
                    name: {type: 'string', title: 'Nombre'},
                    lastName: {type: 'string', title: 'Apellido'},
                    document: {type: 'string', title: 'Cédula'},
                    gender: {
                        type: 'string',
                        title: 'Sexo',
                        enum: ['F', 'M'],
                        enumNames: [t('F'), t('M')],
                        default: 'F'
                    } as any,
                    birthDate: {type: 'string', title: 'Fecha de Nacimiento', format: 'date'},
                    phone: {type: 'string', title: 'Teléfono'},
                },
                required: ["name", "lastName", "document", "gender", "birthDate"]
            }
        },

    }
};

export const UI_SCHEMA = {
    name: {xs: 24, md: 12},
    actionPrice: {xs: 24, md: 12},
    periodicity: {xs: 24, md: 12},
    maxShares: {xs: 24, md: 12},
    sharedFoundAmount: {xs: 24, md: 12},
    meetingHour: {
        "ui:widget": "TimeWidget",
        xs: 24, md: 12
    },
    meetingDay: {
        xs: 24, md: 12,
        "ui:widget": "radio"
    },
    endDate: {
        xs: 24, md: 12
    },
    members: {
        translateIndex: true,
        xs: 24,
        items: {
            name: {xs: 12, md: 12},
            lastName: {xs: 12, md: 12},
            document: {xs: 12, md: 12},
            gender: {xs: 12, md: 12, "ui:widget": "radio", fullWidth: true},
            birthDate: {xs: 24, md: 12},
            phone: {xs: 24, md: 12},
        }
    },
    penalties: {
        "ui:options": {
            "orderable": false
        },
        xs: 24,
        items: {
            name: {xs: 12, md: 12},
            amount: {xs: 12, md: 12}
        }
    },

};
export const WIDGETS = {
    TimeWidget: TimeWidget,
    "radio": RadioWidget
};

export const Form = withTheme(AntDTheme);

export function translateErrors(errors: AjvError[]): AjvError[] {
    return errors.map(error => {
        return Object.assign({}, error, {
            message: t('error.' + error.name)
        });
    });
}

export interface GroupEditData {
    name: string;
    periodicity: number;
    actionPrice: number;
    meetingDay: string;
    meetingHour: string;
    sharedFoundAmount: number;
    maxShares: number;
    endDate: string;
    members: Array<{
        name: string;
        lastName: string;
        document: string;
        birthDate: string;
        gender: string;
        phone: string;
    }>;
    penalties: Array<{
        name: string;
        amount: string;
    }>
}
