import React from 'react';
import './App.css';
import {LoginPage} from "./pages/LoginPage";
import {useUser} from "./AuthContext";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {DashboardPage} from "./pages/DashboardPage";
import {GroupCreationPage} from "./pages/GroupCreationPage";
import {GroupsPage} from "./pages/GroupsPage";
import 'antd/dist/antd.less';
import {GroupPage} from "./pages/GroupPage";
import {useMediaQuery} from '@react-hook/media-query'
import {MobilePage} from './pages/MobilePage';
import {AdminsPage} from "./pages/AdminsPage";
import {MeetingMemberPage} from "./pages/MeetingMemberPage";
import {EndUsersPage} from "./pages/EndUsersPage";
import {CampaignPage} from './pages/CampaignPage';
import {FilterProvider} from "./FilterContext";
import {BaseLayout} from "./components/layout/BasePage";
import {EndUserPage} from "./pages/EndUserPage";

export function App() {

    const {isLogged, user, doLogin} = useUser();
    const isMobile = useMediaQuery('only screen and (max-width: 1000px)')
    const role = user.role;

    const isAdmin = role === 'ADMIN';
    const isManager = isAdmin || role === 'MANAGER';

    if (!isLogged) {
        return <LoginPage doLogin={doLogin}/>
    }

    if (isMobile) {
        return <MobilePage/>
    }

    return <div className="App">
        <FilterProvider>
            <Router>
                <BaseLayout>
                    <Routes isAdmin={isAdmin} isManager={isManager} isSupervisor={role === 'SUPERVISOR'}/>
                </BaseLayout>
            </Router>
        </FilterProvider>

    </div>;
}

export function Routes({isAdmin, isManager, isSupervisor}: { isAdmin: boolean, isManager: boolean, isSupervisor: boolean }) {
    return <Switch>
        <Route path="/groups" exact>
            <GroupsPage/>
        </Route>
        <Route path="/groups/new" exact>
            <GroupCreationPage/>
        </Route>
        <Route path="/groups/:id" exact
               render={p => <GroupPage id={p.match.params.id}/>}/>
        <Route path="/groups/:groupId/meetings/:meetingId/members" exact
               render={p => <MeetingMemberPage meetingId={p.match.params.meetingId}
                                               groupId={p.match.params.groupId}/>}/>
        {isAdmin && <Route
            path="/admins/" exact
            render={_ => <AdminsPage/>}/>}
        <Route
            path="/users/" exact
            render={_ => <EndUsersPage/>}/>
        <Route
            path="/users/:id" exact
            render={b => <EndUserPage id={parseInt(b.match.params.id)}/>}/>
        {isAdmin && <Route
            path="/campaigns/" exact
            render={_ => <CampaignPage/>}/>}
        {isManager && <Route
            path="/campaigns/:campaignId/users" exact
            render={b => <AdminsPage campaignId={b.match.params.campaignId}/>}/>}
        <Route path="/" exact>
            {!isSupervisor ? <DashboardPage/> : <GroupsPage />}
        </Route>
        <Redirect to="/"/>
    </Switch>
}
