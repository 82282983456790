import React from 'react';

import {ArrayFieldTemplateProps, IdSchema, utils} from '@rjsf/core';

import {Button, Col, Row} from "antd";
import {DeleteOutlined, DownOutlined, PlusOutlined, UpOutlined} from "@ant-design/icons";
import {t} from "../../Messages";


const {
    isMultiSelect,
    getDefaultRegistry,
} = utils;

export function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {
    const {schema, registry = getDefaultRegistry()} = props;

    if (isMultiSelect(schema, (registry as any).rootSchema)) {
        return <DefaultFixedArrayFieldTemplate {...props} />;
    } else {
        return <DefaultNormalArrayFieldTemplate {...props} />;
    }
}

type ArrayFieldTitleProps = {
    TitleField: any;
    idSchema: IdSchema;
    title: string;
    required: boolean;
};

const ArrayFieldTitle = ({
                             TitleField,
                             idSchema,
                             title,
                             required,
                         }: ArrayFieldTitleProps) => {
    if (!title) {
        return null;
    }

    const id = `${idSchema.$id}__title`;
    return <TitleField id={id} title={title} required={required}/>;
};

type ArrayFieldDescriptionProps = {
    DescriptionField: any;
    idSchema: IdSchema;
    description: string;
};

const ArrayFieldDescription = ({
                                   DescriptionField,
                                   idSchema,
                                   description,
                               }: ArrayFieldDescriptionProps) => {
    if (!description) {
        return null;
    }

    const id = `${idSchema.$id}__description`;
    return <DescriptionField id={id} description={description}/>;
};

// Used in the two templates
const DefaultArrayItem = (props: React.PropsWithChildren<{
    id?: string;
    hasToolbar: boolean;
    index: number;
    translateIndex?: boolean;
    hasMoveUp: boolean;
    hasMoveDown: boolean;
    disabled: boolean;
    readonly: boolean;
    onReorderClick: (curr: number, target: number) => () => void;
    onDropIndexClick: (idx: number) => () => void;
    hasRemove: boolean;

}>) => {
    const firstLineTitle = !!props.translateIndex;
    const hasToolbar = {props};
    const bodySize = 24 - (firstLineTitle ? 0 : 1) - (hasToolbar ? 3 : 0)
    return <Row align="middle" className={`${props.id}`}>
        <Col xs={firstLineTitle ? 24 : 1} className="idx">
            {firstLineTitle ? t(`${props.id}.${props.index + 1}`) : props.index + 1}
        </Col>
        <Col xs={bodySize}>
            {props.children}
        </Col>

        {props.hasToolbar && (
            <Col xs={3}>
                {(props.hasMoveUp || props.hasMoveDown) && (
                    <Button icon={<UpOutlined/>}
                            disabled={props.disabled || props.readonly || !props.hasMoveUp}
                            tabIndex={-1}
                            onClick={props.onReorderClick(props.index, props.index - 1)}
                    />
                )}

                {(props.hasMoveUp || props.hasMoveDown) && (
                    <Button icon={<DownOutlined/>}
                            tabIndex={-1}
                            disabled={props.disabled || props.readonly || !props.hasMoveDown}
                            onClick={props.onReorderClick(props.index, props.index + 1)}
                    />
                )}

                {props.hasRemove && (
                    <Button type="primary" danger
                            icon={<DeleteOutlined/>}
                            tabIndex={-1}
                            disabled={props.disabled || props.readonly}
                            onClick={props.onDropIndexClick(props.index)}
                    />
                )}
            </Col>
        )}
    </Row>
        ;
};

const DefaultFixedArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    return (
        <fieldset className={props.className}>
            <ArrayFieldTitle
                key={`array-field-title-${props.idSchema.$id}`}
                TitleField={props.TitleField}
                idSchema={props.idSchema}
                title={props.uiSchema['ui:title'] || props.title}
                required={props.required}
            />

            {(props.uiSchema['ui:description'] || props.schema.description) && (
                <div
                    className="field-description"
                    key={`field-description-${props.idSchema.$id}`}
                >
                    {props.uiSchema['ui:description'] || props.schema.description}
                </div>
            )}

            <div
                className="row array-item-list"
                key={`array-item-list-${props.idSchema.$id}`}
            >
                {props.items && props.items.map(DefaultArrayItem)}
            </div>

            {props.canAdd && (
                <Button block
                        className='object-property-expand'
                        icon={<PlusOutlined/>}
                        onClick={props.onAddClick}
                        disabled={props.disabled || props.readonly}>
                    Agregar
                </Button>
            )}
        </fieldset>
    );
};

const DefaultNormalArrayFieldTemplate = (props: ArrayFieldTemplateProps) => {
    return <Col xs={24}>
        <ArrayFieldTitle
            key={`array-field-title-${props.idSchema.$id}`}
            TitleField={props.TitleField}
            idSchema={props.idSchema}
            title={props.uiSchema['ui:title'] || props.title}
            required={props.required}
        />

        {(props.uiSchema['ui:description'] || props.schema.description) && (
            <ArrayFieldDescription
                key={`array-field-description-${props.idSchema.$id}`}
                DescriptionField={props.DescriptionField}
                idSchema={props.idSchema}
                description={
                    props.uiSchema['ui:description'] || props.schema.description
                }
            />
        )}

        <Row key={`array-item-list-${props.idSchema.$id}`}>
            {props.items && props.items.map(p =>
                <Col xs={24} key={p.key} className={`item-${props.idSchema.$id}`}>
                    <DefaultArrayItem {...p} id={props.idSchema.$id} translateIndex={props.uiSchema.translateIndex}/>
                </Col>)}

            {props.canAdd && (
                <Col style={{marginTop: 4, textAlign: 'right'}} xs={24}>
                    <Button block
                            className='object-property-expand'
                            icon={<PlusOutlined/>}
                            onClick={props.onAddClick}
                            disabled={props.disabled || props.readonly}>
                        {t(`${props.idSchema.$id}.add`)}
                    </Button>
                </Col>
            )}
        </Row>
    </Col>
        ;
};


