import {BasePage} from "../components/layout/BasePage";
import {Col, Row, Space} from "antd";
import React from "react";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import {DynamicTable} from "../components/DynamicTable";
import {age, amount, formatDate, money} from "../formatter";
import {t} from "../Messages";
import {useUser} from "../AuthContext";

export function MeetingMemberPage(props: {
    meetingId: string; // meeting id
    groupId: string; // meeting id
}) {

    return <BasePage>
        <Row gutter={[24, 24]}>
            <Col xs={24}>
                <MeetingMemberTable meeting={props.meetingId} groupId={props.groupId}/>
            </Col>
        </Row>

    </BasePage>
}

interface DTMeetingMember {
    id: number;
    end_user_id: number;
    name: string;
    last_name: string;
    birth_date: string;
    gender: string;
    location: string;
    shares_bought: number;
    shares_amount: number;
    social_found: number;
    penalties: number;
    meeting_id: number;
    status: string;

    group_id: never;
}


export function MeetingMemberTable(props: { meeting: string, groupId: string }) {

    const data = usePaginatedTable<DTMeetingMember>('meeting_members', {
        filter: {meeting_id: parseInt(props.meeting), group_id: parseInt(props.groupId)}
    }, true);

    const {user: {role}} = useUser();


    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<DTMeetingMember>
            withTopBorder={false}
            title="Participantes"
            table={data}
            backTo={`/groups/${props.groupId}`}
            extraButtons={<Space>
            </Space>}
            columns={role === 'SUPERVISOR'
                ? [
                    {dataIndex: 'name', title: 'Nombre', render: (_, r) => `${r.name} ${r.last_name || ''}`},
                    {dataIndex: 'birth_date', title: 'Edad', render: age},
                    {dataIndex: 'gender', title: 'Sexo', render: t},
                    {dataIndex: 'created_at', title: 'Registro', render: formatDate},
                    {dataIndex: 'status', title: 'Asistencia', render: t, align: 'right'}
                ]
                : [
                    {dataIndex: 'name', title: 'Nombre', render: (_, r) => `${r.name} ${r.last_name || ''}`},
                    {dataIndex: 'birth_date', title: 'Edad', render: age},
                    {dataIndex: 'gender', title: 'Sexo', render: t},
                    {dataIndex: 'created_at', title: 'Registro', render: formatDate},
                    {dataIndex: 'shares_bought', title: 'Acciones compradas', render: amount, align: 'right'},
                    {dataIndex: 'shares_amount', title: 'Total monto acciones', render: money, align: 'right'},
                    {dataIndex: 'social_found', title: 'Fondo social', render: money, align: 'right'},
                    {dataIndex: 'penalties', title: 'Multas', render: money, align: 'right'},
                    {dataIndex: 'status', title: 'Asistencia', render: t, align: 'right'}
                ]}/>
    </Row>
}

