import {Radio} from 'antd';
import React from 'react';
import {WidgetProps} from "@rjsf/core";
import {toArray} from "antd/es/form/util";


const RadioWidget = ({
                         autofocus,
                         disabled,
                         formContext,
                         id,
                         // label,
                         onBlur,
                         onChange,
                         onFocus,
                         options,
                         // placeholder,
                         readonly,
                         // required,
                         schema,
                         value,
                         uiSchema
                     }: WidgetProps) => {
    const {readonlyAsDisabled = true} = formContext;

    const {enumOptions, enumDisabled} = options;

    const handleChange = ({target: {value: nextValue}}: any) =>
        onChange(schema.type === 'boolean' ? nextValue !== 'false' : nextValue);

    return (
        <Radio.Group
            disabled={disabled || (readonlyAsDisabled && readonly)}
            id={id}
            name={id}
            onChange={!readonly ? handleChange : undefined}
            value={`${value}`}
        >
            {toArray(enumOptions).map(({value: optionValue, label: optionLabel}, i) => (
                <Radio.Button
                    autoFocus={i === 0 ? autofocus : false}
                    disabled={toArray(enumDisabled).indexOf(value) !== -1}
                    key={`${optionValue}`}
                    style={{width: uiSchema.fullWidth ? '100%' : undefined}}
                    value={`${optionValue}`}
                >
                    {optionLabel}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
};

export default RadioWidget;
