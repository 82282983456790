import {Async, AsyncHelper, ReportRequestParam, TableRequest, TableResponse} from "../Model";
import {useCallback, useEffect, useRef, useState} from "react";
import {SorterResult} from "antd/es/table/interface";
import {TablePaginationConfig} from "antd/lib/table";
import {ApiError} from "../Api";
import {useUser} from "../AuthContext";
import {message} from "antd";
import download from 'downloadjs';

export interface TableDS<T> {

    /**
     * The current data
     */
    current: Async<TableResponse<T>>;

    /**
     * The last fetched data.
     *
     * Used to keep the pagination in place and to present
     * a different view the first time and the subsequent
     * page changes.
     */
    previous?: TableResponse<T>;

}

export const TDHelper = {
    noRequested: function <T>(): TableDS<T> {
        return {
            current: AsyncHelper.noRequested()
        }
    },
    beginLoadPage: function <T>(prev: TableDS<T>): TableDS<T> {
        return {
            current: AsyncHelper.fetching(),
            previous: prev.current.state === 'LOADED' ? prev.current.data : undefined
        };
    },
    finishLoadPage: function <T>(newData: TableResponse<T>) {
        return function (prev: TableDS<T>): TableDS<T> {
            return {
                current: AsyncHelper.loaded(newData),
                previous: prev.current.state === 'LOADED' ? prev.current.data : undefined
            };
        }
    },
    mapAndFinish: function <S, T>(newData: TableResponse<S>, mapper: (s: S) => T) {
        return function (prev: TableDS<T>): TableDS<T> {
            return {
                current: AsyncHelper.loaded({
                    ...newData,
                    rows: newData.rows.map(mapper)
                }),
                previous: prev.current.state === 'LOADED' ? prev.current.data : undefined
            };
        }
    },
    map: function <S, T>(ds: TableDS<S>, mapper: (s: S) => T): TableDS<T> {
        let curr: Async<TableResponse<T>>;
        if (ds.current.state === 'LOADED') {
            curr = {
                state: 'LOADED',
                data: {
                    ...ds.current.data,
                    rows: ds.current.data.rows.map(mapper)
                }
            }
        } else {
            curr = {
                ...ds.current
            }
        }
        return {
            current: curr,
            previous: ds.previous ? {...ds.previous, rows: ds.previous.rows.map(mapper)} : undefined
        }
    },
    error: function <T>(error: ApiError) {
        return function (prev: TableDS<T>): TableDS<T> {
            return {
                current: AsyncHelper.error(error),
                previous: prev.current.state === 'LOADED' ? prev.current.data : undefined
            };
        }
    },
};

export type Filters<T extends object> = Partial<{ [k in keyof T]: ReportRequestParam }>;

interface TableQueryConfig<T extends object> {
    page: number,
    size: number,
    filter: Filters<T>,
    sorting: Record<string, boolean>
}


export function usePaginatedTable<T extends object>(
    tableName: string,
    baseConfig?: Partial<TableQueryConfig<T>>,
    autoLoad?: boolean,
    reportName?: string
): PaginatedTable<T> {


    const [data, setData] = useState<TableDS<T>>(TDHelper.noRequested());
    const {api} = useUser();
    const [downloading, setDownloading] = useState(false);

    const [config, setConfig] = useState<TableQueryConfig<T>>({
        page: 1,
        size: 10,
        filter: {},
        sorting: {},
        ...(baseConfig || {}),
    })

    const lastPromise = useRef<Promise<TableResponse<T>>>();

    const loadData = useCallback(async (newConf: TableQueryConfig<T>) => {
        setData(TDHelper.beginLoadPage);
        const currentPromise = api.dynamicTable<T>(tableName, buildRequest(newConf));
        lastPromise.current = currentPromise;
        try {
            const response = await currentPromise;
            if (lastPromise.current !== currentPromise) return;
            setData(TDHelper.finishLoadPage(response))
        } catch (e) {
            if (lastPromise.current !== currentPromise) return;
            setData(TDHelper.error(e))
        }
    }, [tableName, api]);

    const refresh = useCallback(() => {
        loadData(config);
    }, [loadData, config]);

    useEffect(() => {
        if (autoLoad) refresh()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let rows: T[] = [];
    let total: number = 0;
    if (data.current.state === 'LOADED') {
        rows = data.current.data.rows;
        total = data.current.data.count;
    } else if (data.previous) {
        rows = data.previous.rows;
        total = data.previous.count;
    }
    // TODO: evaluate usage of a effect to check for changes on config
    return {
        hasError: data.current.state === 'ERROR',
        isLoading: data.current.state === 'FETCHING',
        firstLoad: data.current.state === 'FETCHING' && !data.previous,
        rows,
        page: config.page,
        total,
        size: config.size,
        filters: config.filter,
        refresh,
        updateFilters: (newF: Filters<T>) => {
            setConfig(cf => ({...cf, filter: newF}));
            loadData({...config, filter: newF});
        },
        setPage: (newP: number) => {
            setConfig(cf => ({...cf, page: newP}));
            loadData({...config, page: newP});
        },
        setPageSize: (newSize: number) => {
            setConfig(cf => ({...cf, size: newSize}));
            loadData({...config, size: newSize});
        },
        setSort: (newOptions: Record<string, boolean>) => {
            setConfig(cf => ({...cf, sorting: removeUndefined(newOptions)}));
            loadData({...config, sorting: removeUndefined(newOptions)});
        },
        onChange: (
            pagination: TablePaginationConfig,
            filter: unknown,
            newOptions: SorterResult<T> | SorterResult<T>[]
        ) => {
            const newSorting = mapFromAntd(newOptions);

            setConfig(cf => ({
                ...cf,
                sorting: newSorting,
                page: pagination.current || 1,
                size: pagination.pageSize || 10
            }));

            loadData({
                ...config,
                sorting: newSorting,
                page: pagination.current || 1,
                size: pagination.pageSize || 10
            });
        },
        download: () => {
            setDownloading(true);
            api.dynamicTableDownload(reportName || tableName, buildRequest(config))
                .then(blob => {
                    const ct = 'text/csv';
                    download(blob, tableName + ".csv", ct);
                })
                .catch(e => {
                    console.error(e);
                    message.error("Error al descargar reporte, contacte con la Fundación.");
                })
                .finally(() => setDownloading(false))
        },
        downloading: downloading
    };
}

export interface PaginatedTable<T extends object> {
    download: () => void;
    hasError: boolean;
    isLoading: boolean;
    firstLoad: boolean;
    downloading: boolean;
    rows: T[],
    page: number;
    total: number;
    size: number;
    filters: Filters<T>;
    refresh: () => void;
    updateFilters: (newF: Filters<T>) => void;
    setPage: (newP: number) => void;
    setPageSize: (newP: number) => void;
    setSort: (newO: Record<string, boolean>) => void;
    onChange: (p: TablePaginationConfig, f: unknown, newOptions: SorterResult<T> | SorterResult<T>[]) => void;
}

function buildRequest(newConf: TableQueryConfig<object>): TableRequest {
    return {
        order: mapFromAntd(newConf.sorting),
        params: newConf.filter as any,
        offset: (newConf.page - 1) * newConf.size,
        limit: newConf.size
    };
}

function removeUndefined<T>(param: Record<string, T>): Record<string, T> {
    const toRet: Record<string, T> = {};
    for (const key in param) {
        if (!param.hasOwnProperty(key)) continue;
        const val = param[key];
        if (val !== undefined) {
            toRet[key] = val;
        }
    }
    return toRet;
}

function mapFromAntd<T>(newOptions: SorterResult<T> | SorterResult<T>[]) {
    const asArr = Array.isArray(newOptions)
        ? newOptions
        : [newOptions];

    return asArr.reduce((result, curr) => {
        const field = curr.field;
        if (!field
            || !curr.order
            || typeof field != 'string'
        ) return result;
        result[field] = curr.order !== 'descend';
        return result;
    }, {} as Record<string, boolean>)
}
