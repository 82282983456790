import React, {useCallback, useContext, useMemo} from "react";
import {Api} from "./Api";
import {LoginResponse} from "./Model";
import {message} from "antd";
import {useLocalStorage} from "react-use";


const NO_LOGGED_USER: LoginResponse = {
    name: 'NO_USER',
    token: 'DEFAULT_TOKEN',
    username: 'NO_USER',
    role: 'SUPERVISOR' as const,
    campaign: {name: 'NO_CAMPAIGN', id: null},
    version: 4
};

const AuthContext = React.createContext({
    user: NO_LOGGED_USER,
    doLogin: (username: string, pass: string) => {
        return Promise.resolve();
    },
    doLogout: () => {
    }
});

export function AuthProvider(props: {
    children: React.ReactNode;
}) {

    const [token, setToken] = useLocalStorage<LoginResponse>('user', NO_LOGGED_USER, {
        raw: false,
        serializer: val => JSON.stringify(val),
        deserializer: (val) => {
            if (!val) return NO_LOGGED_USER;
            const des: LoginResponse = JSON.parse(val);
            if (des.version !== 4) return NO_LOGGED_USER;
            return des;
        }
    });

    const doLogin = useCallback(async (username: string, password: string) => {
        try {
            const d = await new Api().login(username, password);
            setToken({...d, version: 4});
            message.success({content: `Bienvenido ${d.name}`, key: 'login-msg', duration: 5});
        } catch (e) {
            message.warn({
                content: "Usuario no encontrado o contraseña incorrecta",
                key: 'login-msg',
                variant: 'warning',
                duration: 15
            });
            throw e;
        }
    }, [setToken]);

    const doLogout = useCallback(() => {
        console.log('Performing logout');
        setToken(NO_LOGGED_USER)
    }, [setToken]);

    const val = useMemo(() => ({
        user: (token || NO_LOGGED_USER),
        doLogin,
        doLogout
    }), [doLogin, token, doLogout]);

    return <AuthContext.Provider value={val}>
        {props.children}
    </AuthContext.Provider>
}

export function useUser() {
    const auth = useContext(AuthContext);
    const token = auth.user.token;
    const api = useMemo(() => new Api(token, auth.doLogout), [token, auth.doLogout]);
    return {
        user: auth.user,
        api,
        isLogged: auth.user.token !== NO_LOGGED_USER.token,
        logout: auth.doLogout,
        doLogin: auth.doLogin
    };
}
