import {useApi} from "../useApi";
import {useMemo} from "react";
import {Async, AsyncHelper, ReportRequest, ReportRequestParam, ReportResponse, SingleReportData} from "../Model";
import {PieReport} from "../components/report/PieReport";
import {BarReport} from "../components/report/BarReport";
import {Button, Col, message, Row} from "antd";
import {useFilter} from "../FilterContext";
import {jsPDF} from "jspdf";
import {html2PDF, Opts} from "../util/Pdf2Canva";


export function DashboardPage() {

    const {filter} = useFilter();

    const params: ReportRequest = useMemo(() => {
        const p: Record<string, ReportRequestParam> = {
            campaign: filter.campaign,
            group: filter.group,
            end_user: filter.endUser,
            date: filter.date,
            city: filter.city,
            department: filter.department
        };
        return {
            'report.dashboard.founds': p,
            'report.dashboard.groups': p,
            'report.dashboard.location': p,
            'report.dashboard.age': p,
            'report.dashboard.gender': p,
        }
    }, [filter])
    const reports = useApi('report', 'DASHBOARD', params);


    return <Row gutter={8} align="middle" justify="center">
        <Col xs={23}>
            <Graphs data={reports}/>
        </Col>
    </Row>
}

function Graphs(props: { data: Async<ReportResponse> }) {

    const totals = extractReport('report.dashboard.founds', props.data);
    const groups = extractReport('report.dashboard.groups', props.data);
    const city = extractReport('report.dashboard.location', props.data);
    const age = extractReport('report.dashboard.age', props.data);
    const gender = extractReport('report.dashboard.gender', props.data);

    function generatePdf() {
        const div = document.querySelector("#reports");
        if (!div) {
            message.error("No se puede generar reporte, contacta con el Admin");
            return;
        }
        html2PDF(div as unknown as HTMLElement, {
            output: 'dashboard.pdf',
            margin: {
                top: 100,
                bottom: 100,
                left: 20,
                right: 20
            },
            init: (opts: Opts, pdf: jsPDF) => {
                pdf.text("Kakua", 10, 10);
            }
        })
    }

    return <>
        <Row gutter={[24, 24]} align="middle" id="reports">
            <Col xs={12} md={8}>
                <div style={{background: 'white'}}>
                    <PieReport report={totals}/>
                </div>
            </Col>
            <Col xs={12} md={8}>
                <div style={{background: 'white'}}>
                    <BarReport report={city} translateX={false} with2Dims={true} withLegend={true}/>
                </div>
            </Col>
            <Col xs={12} md={8}>
                <div style={{background: 'white'}}>
                    <PieReport report={groups}/>
                </div>
            </Col>
            <Col xs={12} md={8}>
                <div style={{background: 'white'}}>
                    <BarReport report={age}/>
                </div>
            </Col>
            <Col xs={12} md={8}>
                <div style={{background: 'white'}}>
                    <PieReport report={gender}/>
                </div>
            </Col>
        </Row>
        <Row gutter={[24, 24]} align="middle" id="reports">
            <Col xs={24} className="download-report-button">
                <Button onClick={generatePdf}>Descargar reporte</Button>
            </Col>
        </Row>
    </>

}

export function extractReport(name: string,
                              source: Async<ReportResponse>): SingleReportData {
    return {
        title: name,
        data: AsyncHelper.map(source, d => d.data[name]?.data || [])
    }
}
