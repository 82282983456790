import {PaginatedTable} from "../hooks/usePaginatedTable";
import {ColumnProps} from "antd/es/table";
import {Button, Col, Popover, Row, Space, Table, TableProps} from "antd";
import {Sidebar} from "./sidebar/Sidebar";
import {ArrowDownOutlined, ArrowLeftOutlined, SyncOutlined} from '@ant-design/icons';
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useUser} from "../AuthContext";


export function DynamicTable<T extends object>({
                                                   title,
                                                   subtitle,
                                                   table,
                                                   columns,
                                                   extraButtons,
                                                   withTopBorder,
                                                   rowKey,
                                                   backTo,
                                                   extraTableProps
                                               }: {
    title?: string;
    subtitle?: React.ReactNode;
    table: PaginatedTable<T>;
    columns: ColumnProps<T>[];
    extraButtons?: React.ReactNode;
    withTopBorder?: boolean;
    rowKey?: string | ((r: T) => string);
    backTo?: string;
    extraTableProps?: TableProps<T>
}) {

    const [isOpen] = useState(false);
    const {user: {role}} = useUser();

    return <Sidebar isOpen={isOpen} withBorder={withTopBorder}>
        <Row gutter={[16, 16]} style={{padding: 12}}>
            <Col xs={24}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {backTo && <Link to={backTo}>
                        <ArrowLeftOutlined/>
                    </Link>}
                    <div style={{flex: 10}}>
                        <h4>{title}</h4>
                        {subtitle}
                    </div>
                    <div>
                        <Space>
                            {extraButtons}
                            <Popover content='Actualizar'>
                                <Button type="primary"
                                        className="ant-btn-icon-only"
                                        ghost
                                        onClick={table.refresh}>
                                    <SyncOutlined spin={table.isLoading}/>
                                </Button>
                            </Popover>
                            {role !== 'SUPERVISOR' && <Popover content='Download'>
                                <Button type="primary"
                                        className="ant-btn-icon-only"
                                        disabled={table.downloading}
                                        ghost
                                        onClick={table.download}>
                                    <ArrowDownOutlined spin={table.downloading}/>
                                </Button>
                            </Popover>}
                        </Space>
                    </div>
                </div>
            </Col>

            <Col xs={24}>
                <Table<T> dataSource={table.rows}
                          className="hide-responsive"
                          loading={table.isLoading}
                          rowKey={rowKey || 'id'}
                          size="small"
                          onChange={table.onChange}
                          pagination={{
                              defaultCurrent: 1,
                              defaultPageSize: 10,
                              total: table.total,
                              current: table.page
                          }}
                          columns={columns}
                          {...extraTableProps}
                />
            </Col>
        </Row>
    </Sidebar>
}
