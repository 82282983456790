import {useUser} from "../../AuthContext";
import {Layout, Menu, Space} from "antd";
import './BasePage.less';
import {Link, useLocation} from 'react-router-dom';
import logo from '../../assets/logo-with-text.svg';
import {Filter} from "../main-filter/Filter";

export function BasePage(props: React.PropsWithChildren<{}>) {
    return <>{props.children}</>
}

export function BaseLayout(props: React.PropsWithChildren<{}>) {

    const {user, logout} = useUser();
    const location = useLocation();

    const isAdmin = user.role === 'ADMIN';
    const isManager = user.role === 'MANAGER';
    const isSupervisor = user.role === 'SUPERVISOR';
    const campaignName = user.campaign.name;
    const campaignId = user.campaign.id;

    return <Layout className="base-page">
        <Layout>
            <Layout.Sider width={200} className="left-menu">
                <Menu mode="inline" style={{borderRight: 0}} selectedKeys={[location.pathname]}>
                    <Menu.Item className="logo" key="/home">
                        <img src={logo} alt="Kakua" style={{maxWidth: '100%'}}/>
                    </Menu.Item>
                    {!isSupervisor && <Menu.Item key="/">
                        <Link to="/">
                            Datos generales
                        </Link>
                    </Menu.Item>}
                    <Menu.Item key="/groups">
                        <Link to="/groups">
                            Grupos de ahorro
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/users">
                        <Link to="/users">
                            Participantes
                        </Link>
                    </Menu.Item>
                    {isAdmin && <Menu.Item key="/admins">
                        <Link to="/admins">
                            Administradores
                        </Link>
                    </Menu.Item>}
                    {isAdmin && <Menu.Item key="/campaigns">
                        <Link to="/campaigns">
                            Campañas
                        </Link>
                    </Menu.Item>}
                    {isManager && <Menu.Item key="/campaigns">
                        <Link to={`/campaigns/${campaignId}/users`}>
                            Campaña '{campaignName}'
                        </Link>
                    </Menu.Item>}
                    <Space/>
                </Menu>
                <Menu mode="inline" style={{borderRight: 0}}>
                    <Menu.Item key="5" onClick={logout}>Cerrar sesión</Menu.Item>
                </Menu>
            </Layout.Sider>
            <Layout>
                <Layout.Content className="kakuapp-content">
                    <div className="app-content">
                        <div className="app-filter">
                            <Filter/>
                        </div>
                        <div className="app-main-content">
                            {props.children}
                        </div>
                    </div>
                </Layout.Content>
            </Layout>
        </Layout>
    </Layout>
}

