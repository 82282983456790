import React, {useCallback, useState} from 'react';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {Button, Form, Input} from 'antd';
import logo from '../assets/logo.svg';
import {t} from "../Messages";


export function LoginPage(props: {
    doLogin: (user: string, pass: string) => Promise<void>;
}) {
    const [working, setWorking] = useState(false);

    function onFormSubmit(username: string, password: string) {
        setWorking(true);
        props.doLogin(username, password)
            .catch(() => {
                setWorking(false);
            });
    }

    return <div style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        <div>
            <LoginForm onFormSubmit={onFormSubmit} working={working}/>

            <div style={{color: 'gray', textAlign: 'center', width: '100%', fontSize: '0.8em'}}>
                Version: {process.env.REACT_APP_VERSION}
            </div>
        </div>
    </div>
}


/// COMPONENTS

function LoginForm(props: {
    onFormSubmit(username: string, password: string): void;
    working: boolean;
}) {

    const [form] = Form.useForm();
    const {onFormSubmit, working} = props;

    const handleSubmit = useCallback(() => {
        form.validateFields(['username', 'password']).then(store => {
            onFormSubmit(store.username, store.password);

        });
    }, [form, onFormSubmit])

    return <Form onFinish={handleSubmit}
                 form={form}
                 style={{textAlign: 'center'}}>
        <Form.Item>
            <img src={logo} alt="Kakua" style={{maxWidth: 100}}/>
        </Form.Item>
        <Form.Item>
            <h1>{t('login.title')}</h1>
        </Form.Item>
        <Form.Item name="username" rules={[{required: true, message: t('login.user_required')}]}>
            <Input
                prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                disabled={working}
                placeholder={t('login.user_placeholder')}
            />
        </Form.Item>
        <Form.Item name="password" rules={[{required: true, message: t('login.pass_required')}]}>
            <Input
                prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                type="password"
                disabled={working}
                placeholder={t('login.pass_placeholder')}
            />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button" loading={props.working}>
                {!working ? t('login.login_button') : t('login.login_working')}
            </Button>
        </Form.Item>
    </Form>;
}
