import {Bar} from 'react-chartjs-2';
import {Datum, SingleReportData} from "../../Model";
import {useMemo} from "react";
import {t} from "../../Messages";
import {Spin} from "antd";

export function BarReport({report, translateX, with2Dims, withLegend}: {
    report: SingleReportData,
    with2Dims?: boolean,
    translateX?: boolean,
    withLegend?: boolean
}) {
    return <div style={{padding: 10}}>
        <h4>{t(`${report.title}.title`)}</h4>
        {report.data.state === 'LOADED'
            ? <LoadedBar data={report.data.data} name={report.title} translateX={!!translateX} with2Dims={!!with2Dims}
                         withLegend={!!withLegend}/>
            : <Spin size="large"/>
        }
    </div>
}

function LoadedBar(props: { name: string, data: Datum[], translateX: boolean, with2Dims: boolean, withLegend: boolean }) {

    const finalData = useMemo(() => mapToChart(props.name, props.data, props.translateX, props.with2Dims),
        [props.name, props.data, props.translateX, props.with2Dims]);

    if (props.data.map(d => d.x).reduce((d1, d2) => d1 + d2, 0) === 0) {
        return <div style={{color: 'darkgray'}}>Sin datos para el filtro seleccionado</div>
    }

    return <Bar data={finalData}
                type="bar"
                height={300}
                options={{
                    plugins: {
                        legend: {
                            display: props.withLegend,
                            position: 'bottom',
                            align: 'start'
                        },
                    }
                }}/>
}

export function mapToChart(key: string, data: Datum[], translateX: boolean, with2Dims: boolean) {

    const rows = (data || []);
    const labels = rows.map(r => translateX ? t(`${key}.${r.y}`) : r.y);
    const values = rows.map(r => r.x);

    const base = {

        barThickness: 10,
        maxBarThickness: 10,
        minBarLength: with2Dims ? 5 : 20,

        borderWidth: 1
    }

    if (with2Dims) {

        return {
            labels,
            datasets: [{
                ...base,
                label: t(`${key}.xLabel`),
                data: values,
                backgroundColor: Colors[0],
                borderColor: Colors[0],
            }, {
                ...base,
                label: t(`${key}.xLabel2`),
                data: rows.map(r => r.x2!),
                backgroundColor: Colors[1],
                borderColor: Colors[1],
            }]
        }

    }


    return {
        labels,
        datasets: [{
            ...base,
            label: t(`${key}.xLabel`),
            data: values,
            backgroundColor: Colors,
            borderColor: Colors,
        }]
    }
}

const Colors = [
    '#4CA759',
    '#B1D038',
    '#7F54E1',
    '#2866AD',
    '#2C3487',
    '#402A71',
    '#D83837',
    '#E79EC0',
    '#F9751A',
    '#FDD200'
]
