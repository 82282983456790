import React from 'react';
import {ObjectFieldTemplateProps, utils} from '@rjsf/core';
import {Button, Col, Row} from "antd";
import {PlusOutlined} from '@ant-design/icons';
import {JSONSchema7Definition} from "json-schema";

const {canExpand} = utils;


export function ObjectFieldTemplate({
                                        DescriptionField,
                                        description,
                                        TitleField,
                                        title,
                                        properties,
                                        required,
                                        disabled,
                                        readonly,
                                        uiSchema,
                                        idSchema,
                                        schema,
                                        formData,
                                        onAddClick,
                                    }: ObjectFieldTemplateProps) {
    return (
        <Row gutter={16}>
            {(uiSchema['ui:title'] || title) && <Col xs={24} className={`${idSchema}-title`}>
                <TitleField
                    id={`${idSchema.$id}-title`}
                    title={title}
                    required={required}
                />
            </Col>}
            {description && <Col xs={24} className={`${idSchema}-description`}>
                <DescriptionField
                    id={`${idSchema.$id}-description`}
                    description={description}
                />
            </Col>}
            <Row gutter={16} style={{padding: 10}}>
                {properties.map((element: any, index: number) => (
                    <Col
                        xs={uiSchema[element.name]?.xs || 24}
                        md={uiSchema[element.name]?.md || 24}
                        key={index}
                        style={{paddingBottom: 10}}
                    >
                        {element.content}
                        <Desc f={schema.properties?.[element.name]}/>
                    </Col>
                ))}
                {canExpand(schema, uiSchema, formData) && (
                    <Row justify="end">
                        <Col>
                            <Button block
                                    className='object-property-expand'
                                    icon={<PlusOutlined/>}
                                    onClick={onAddClick(schema)}
                                    disabled={disabled || readonly}>
                                Agregar
                            </Button>
                        </Col>
                    </Row>
                )}
            </Row>
        </Row>
    );
}

function Desc({f}: { f?: JSONSchema7Definition }) {
    if (f === undefined || f === true || f === false) return <></>
    return <div className="field-description">{f.description}</div>
}
