import {BasePage} from "../components/layout/BasePage";
import {Button, Col, Form, Input, message, Modal, Row, Space} from "antd";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import {DynamicTable} from "../components/DynamicTable";
import {useBoolean} from "../hooks/useBool";
import React, {useCallback} from "react";
import {t} from "../Messages";
import {UserOutlined} from "@ant-design/icons";
import {useUser} from "../AuthContext";
import {Link} from "react-router-dom";

export interface CampaignDTable {
    campaign_id: number;
    name: string;
}

export function CampaignPage() {
    return <BasePage>
        <Row gutter={[24, 24]}>
            <Col xs={24}>
                <CampaignTable/>
            </Col>
        </Row>
    </BasePage>
}

export function CampaignTable() {

    const data = usePaginatedTable<CampaignDTable>('campaigns', {}, true);
    const modalAdd = useBoolean();

    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<CampaignDTable>
            title="Datos de campañas"
            rowKey="campaign_id"
            table={data}
            extraButtons={<Button key="add" type="primary" onClick={modalAdd.setTrue}>Agregar campaña</Button>}
            columns={[
                {dataIndex: 'name', title: 'Nombre'},
                {
                    dataIndex: 'id', title: '', render: (_, r) => <>
                        <Space>
                            <Link to={`/campaigns/${r.campaign_id}/users`}>
                                <Button>Ver usuarios</Button>
                            </Link>
                        </Space>
                    </>
                }
            ]}/>
        <AddCampaignModal visible={modalAdd.value} onComplete={() => {
            data.refresh();
            modalAdd.setFalse()
        }} onCancel={modalAdd.setFalse}/>
    </Row>
}

function AddCampaignModal({visible, onComplete, onCancel}:
                              { visible: boolean, onComplete: () => void, onCancel: () => void }) {

    const [form] = Form.useForm();
    const working = useBoolean(false);
    const {api} = useUser();

    const handleSubmit = useCallback(() => {
        working.setTrue();
        form.validateFields(['name']).then(store => {
            api.addCampaign({name: store.name})
                .then(() => {
                    message.success({
                        content: `Campaña '${store.name}' creada`,
                        key: 'reset-pin-admin',
                        duration: 5
                    })
                    onComplete();
                })
                .catch(e => {
                    console.error(e);
                    message.error({content: 'Error al cambiar clave de usuario', key: 'reset-pin-admin', duration: 5});
                })
                .finally(working.setFalse)
        });
    }, [form, onComplete, api, working])

    return <Modal visible={visible}
                  onOk={form.submit}
                  okButtonProps={{loading: working.value}}
                  cancelButtonProps={{loading: working.value}}
                  okText={working.value ? t('generic.saving') : t('generic.add')}
                  title="Agregar campaña"
                  onCancel={onCancel}>
        <Form onFinish={handleSubmit}
              layout="vertical"
              form={form}>
            <Form.Item name="name"
                       label={t('campaign.name')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Input
                    prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    disabled={working.value}
                />
            </Form.Item>
        </Form>
    </Modal>
}
