import {BasePage} from "../components/layout/BasePage";
import {useApi} from "../useApi";
import {ReportRequest, ReportRequestParam} from "../Model";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {extractReport} from "./DashboardPage";
import {PieReport} from "../components/report/PieReport";
import {Button, Col, Form, message, Modal, Row, Select, Space} from "antd";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import {t} from "../Messages";
import {Link} from "react-router-dom";
import {DynamicTable} from "../components/DynamicTable";
import {formatDate} from "../formatter";
import {useFilter} from "../FilterContext";
import {useBoolean} from "../hooks/useBool";
import {useUser} from "../AuthContext";
import {AdminDTable} from "./AdminsPage";
import {getMessage} from "../Api";

export interface GroupDTable {
    sessions: number;
    meeting_day: string;
    city: string;
    department: string;
    periodicity: string;
    name: string;
    meeting_hour: string;
    created_at: string;
    end_date: string;
    location: string;
    every: number;
    image_id: string;
    cycle: number;
    parent_group_id: number;
    campaign: { id: number, name: string };
    supervisor: { id: number, name: string };
    campaign_id: never;
    group_id: number;
}

export function GroupsPage() {

    const {user: {role}} = useUser();
    return <BasePage>
        <Row gutter={[24, 24]}>
            {role !== 'SUPERVISOR' && <Col xs={24}>
                <GroupDashboard/>
            </Col>}
            <Col xs={24}>
                <GroupTable/>
            </Col>
        </Row>
    </BasePage>
}

export function GroupDashboard() {

    const {filter} = useFilter();

    const params: ReportRequest = useMemo(() => {
        const p: Record<string, ReportRequestParam> = {
            campaign: filter.campaign,
            group: filter.group,
            end_user: filter.endUser,
            date: filter.date,
            city: filter.city,
            department: filter.department
        };
        return {
            'report.dashboard.founds': p,
            'report.dashboard.groups': p,
            'report.dashboard.location': p,
            'report.dashboard.age': p,
            'report.dashboard.gender': p,
        }
    }, [filter]);
    const reports = useApi('report', 'DASHBOARD', params);

    const totals = extractReport('report.dashboard.founds', reports);
    const groups = extractReport('report.dashboard.groups', reports);

    return <Row gutter={[24, 24]} align="middle" justify="center">
        <Col xs={8}>
            <div style={{background: 'white'}}>
                <PieReport report={totals}/>
            </div>
        </Col>
        <Col xs={8}>
            <div style={{padding: 40, background: 'white'}}>
                <h4>Promedio de ahorro mensual</h4>
                <div style={{color: 'darkgray'}}>No hay datos</div>
            </div>
        </Col>
        <Col xs={8}>
            <div style={{background: 'white'}}>
                <PieReport report={groups}/>
            </div>
        </Col>
    </Row>
}

export function GroupTable() {

    const {filter} = useFilter();
    const {user} = useUser();
    const data = usePaginatedTable<GroupDTable>('groups');
    const [groupToChangeSupervisor, setGroupToChangeSupervisor] = useState<GroupDTable>();

    useEffect(() => {
        data.updateFilters({
            group_id: filter.group,
            campaign_id: filter.campaign,
            created_at: filter.date,
            city: filter.city,
            department: filter.department
        })
        // eslint-disable-next-line
    }, [filter]);

    return <Row gutter={6} align="middle" justify="center" style={{background: 'white'}}>
        <DynamicTable<GroupDTable>
            title="Datos por comité"
            subtitle={<p style={{
                textAlign: 'left',
                color: 'gray',
                fontSize: '0.9em'
            }}>* Esta es la fecha pre-establecida, sin tener en cuenta postergaciones</p>}
            table={data}
            rowKey="group_id"
            extraButtons={<Link to="/groups/new">
                <Button type="primary">Nuevo</Button>
            </Link>}
            columns={[
                {
                    dataIndex: 'end_date',
                    title: 'Estado',
                    render: d => d ? 'Finalizado' : 'Activo',
                    width: 60,
                    fixed: 'left'
                },
                {
                    dataIndex: 'created_at',
                    title: 'Apertura',
                    align: 'left',
                    render: formatDate,
                    width: 80,
                    fixed: 'left'
                },
                {
                    dataIndex: 'name',
                    title: 'Nombre',
                    render: (_, g) => `${g.name} (${g.cycle || ''})`,
                    width: 120,
                    fixed: 'left'
                },
                {dataIndex: 'sessions', title: 'Sesiones', width: 100},
                {
                    dataIndex: 'periodicity',
                    title: 'Frecuencia',
                    render: (_, r) => `Cada ${r.every} ${t(r.periodicity)}`,
                    width: 100
                },
                {dataIndex: 'department', title: 'Departamento', width: 100},
                {dataIndex: 'city', title: 'Ciudad', width: 60},
                {dataIndex: 'meeting_day', title: 'Dia*', render: t, width: 40},
                {dataIndex: 'meeting_hour', title: 'Hora*', width: 60}, // 530
                {dataIndex: 'campaign', title: 'Campaña', render: (_, r) => r.campaign?.name, width: 50},
                {dataIndex: 'supervisor', title: 'Facilitador', render: (_, r) => r.supervisor?.name, width: 50},
                {
                    dataIndex: 'group_id',
                    title: '',
                    width: 100,
                    render: (id, row) => <>
                        <Space direction="vertical" align="center">
                            <Link to={`/groups/${id}`}>
                                <Button>Más info</Button>
                            </Link>
                            {user.role === 'ADMIN' || user.role === 'MANAGER'
                                ? <Button onClick={() => setGroupToChangeSupervisor(row)}>
                                    Cambiar facilitador
                                </Button>
                                : null}
                        </Space>
                    </>
                },
            ]}
            extraTableProps={{
                scroll: {
                    x: 730
                }
            }}
        />
        <ChangeSupervisor visible={!!groupToChangeSupervisor}
                          prevData={groupToChangeSupervisor}
                          onComplete={() => {
                              data.refresh();
                              setGroupToChangeSupervisor(undefined);
                          }}
                          onCancel={() => setGroupToChangeSupervisor(undefined)}
        />
    </Row>
}

function ChangeSupervisor({visible, onComplete, onCancel, prevData}:
                              {
                                  visible: boolean,
                                  prevData?: GroupDTable,
                                  onComplete: () => void,
                                  onCancel: () => void,
                              }) {

    const [form] = Form.useForm();
    const working = useBoolean(false);
    const {api} = useUser();
    const [supervisors, setSupervisors] = useState<AdminDTable[]>([]);

    const oldSupervisor = prevData?.supervisor;

    useEffect(() => {
        if (!oldSupervisor) return;
        form.setFieldsValue({
            supervisor: oldSupervisor.name
        });
    }, [oldSupervisor, form]);

    useEffect(() => {
        api.dynamicTable<AdminDTable>('admins', {
            offset: 0,
            limit: 1000,
            params: {
                role: 'SUPERVISOR'
            }
        }).then(cs => {
            setSupervisors(cs.rows);
        }).catch(e => {
            console.warn(e);
        });
    }, [api]);

    const handleSubmit = useCallback(() => {
        if (!prevData) return;
        const group = prevData;
        working.setTrue();
        form.validateFields(['supervisor']).then(store => {
            const supervisorId = store.supervisor;
            api.setSupervisor(group.group_id, supervisorId).then(() => {
                message.success({
                    content: `Facilitador del grupo '${group.name}' cambiado`,
                    key: 'edit-group',
                    duration: 5
                });
                onComplete();
            }).catch(e => {
                console.error(e);
                message.error({content: getMessage(e, 'Error al guardar grupo'), key: 'edit-group', duration: 5});
            }).finally(working.setFalse)
        });
    }, [form, onComplete, api, working, prevData])

    function unsetAdmin() {
        if (!prevData) return;
        working.setTrue();
        api.removeSupervisor(prevData.group_id).then(() => {
            message.success({
                content: `Facilitador del grupo '${prevData.name}' eliminado`,
                key: 'edit-group',
                duration: 5
            });
            onComplete();
        }).catch(e => {
            console.error(e);
            message.error({content: getMessage(e, 'Error al guardar grupo'), key: 'edit-group', duration: 5});
        }).finally(working.setFalse)
    }

    return <Modal visible={visible}
                  onOk={form.submit}
                  okButtonProps={{loading: working.value}}
                  cancelButtonProps={{loading: working.value}}
                  okText={working.value ? t('generic.saving') : t(prevData ? 'generic.save' : 'generic.add')}
                  title="Cambiando facilitador de grupo"
                  onCancel={onCancel}>
        <Form onFinish={handleSubmit}
              layout="vertical"
              form={form}>
            <Form.Item name="supervisor"
                       label={t('edit_group.supervisor')}
                       rules={[{required: true, message: t('generic.required')}]}>
                <Select style={{width: '100%'}}>
                    {supervisors.map(c => <Select.Option value={c.id}
                                                         key={c.id}>{c.name}</Select.Option>)}
                </Select>
            </Form.Item>
            {prevData?.supervisor?.id && <Form.Item>
                <Button block danger onClick={unsetAdmin}>
                    Eliminar facilitador del grupo
                </Button>
            </Form.Item>}
        </Form>
    </Modal>
}
