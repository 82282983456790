import {useCallback, useMemo, useState} from "react";

export function useBoolean(initialValue: boolean = false) {
    const [value, setValue] = useState(initialValue)

    const setTrue = useCallback(() => setValue(true), [])
    const setFalse = useCallback(() => setValue(false), [])
    const toggle = useCallback(() => setValue(value => !value), [])

    return useMemo(() => ({value, setTrue, setFalse, toggle, setValue}),
        [value, setTrue, setFalse, toggle, setValue]);
}
