export interface CityType {
    id: number;
    city: string;
    department: string;
}

export const Cities: CityType[] = [{department: "ASUNCION", city: "ASUNCION"},
    {department: "CONCEPCION", city: "CONCEPCION"},
    {department: "CONCEPCION", city: "ARROYITO"},
    {department: "CONCEPCION", city: "BELEN"},
    {department: "CONCEPCION", city: "HORQUETA"},
    {department: "CONCEPCION", city: "LORETO"},
    {department: "CONCEPCION", city: "SAN CARLOS DEL APA"},
    {department: "CONCEPCION", city: "SAN LAZARO"},
    {department: "CONCEPCION", city: "YBY YAU"},
    {department: "CONCEPCION", city: "AZOTE'Y"},
    {department: "CONCEPCION", city: "SARGENTO JOSE FELIX LOPEZ"},
    {department: "CONCEPCION", city: "SAN ALFREDO"},
    {department: "CONCEPCION", city: "PASO BARRETO"},
    {department: "SAN PEDRO", city: "SAN PEDRO DEL YCUAMANDYYU"},
    {department: "SAN PEDRO", city: "ANTEQUERA"},
    {department: "SAN PEDRO", city: "CHORE"},
    {department: "SAN PEDRO", city: "GENERAL ELIZARDO AQUINO"},
    {department: "SAN PEDRO", city: "ITACURUBI DEL ROSARIO"},
    {department: "SAN PEDRO", city: "LIMA"},
    {department: "SAN PEDRO", city: "NUEVA GERMANIA"},
    {department: "SAN PEDRO", city: "SAN ESTANISLAO"},
    {department: "SAN PEDRO", city: "SAN PABLO"},
    {department: "SAN PEDRO", city: "TACUATI"},
    {department: "SAN PEDRO", city: "UNION"},
    {department: "SAN PEDRO", city: "25 DE DICIEMBRE"},
    {department: "SAN PEDRO", city: "VILLA DEL ROSARIO"},
    {department: "SAN PEDRO", city: "GENERAL FRANCISCO ISIDORO RESQUIN"},
    {department: "SAN PEDRO", city: "YATAITY DEL NORTE"},
    {department: "SAN PEDRO", city: "GUAJAYVI"},
    {department: "SAN PEDRO", city: "CAPIIBARY"},
    {department: "SAN PEDRO", city: "SANTA ROSA DEL AGUARAY"},
    {department: "SAN PEDRO", city: "YRYBUCUA"},
    {department: "SAN PEDRO", city: "LIBERACION"},
    {department: "CORDILLERA", city: "CAACUPE"},
    {department: "CORDILLERA", city: "ALTOS"},
    {department: "CORDILLERA", city: "ARROYOS Y ESTEROS"},
    {department: "CORDILLERA", city: "ATYRA"},
    {department: "CORDILLERA", city: "CARAGUATAY"},
    {department: "CORDILLERA", city: "EMBOSCADA"},
    {department: "CORDILLERA", city: "EUSEBIO AYALA"},
    {department: "CORDILLERA", city: "ISLA PUCU"},
    {department: "CORDILLERA", city: "ITACURUBI DE LA CORDILLERA"},
    {department: "CORDILLERA", city: "JUAN DE MENA"},
    {department: "CORDILLERA", city: "LOMA GRANDE"},
    {department: "CORDILLERA", city: "MBOCAYATY DEL YHAGUY"},
    {department: "CORDILLERA", city: "NUEVA COLOMBIA"},
    {department: "CORDILLERA", city: "PIRIBEBUY"},
    {department: "CORDILLERA", city: "PRIMERO DE MARZO"},
    {department: "CORDILLERA", city: "SAN BERNARDINO"},
    {department: "CORDILLERA", city: "SANTA ELENA"},
    {department: "CORDILLERA", city: "TOBATI"},
    {department: "CORDILLERA", city: "VALENZUELA"},
    {department: "CORDILLERA", city: "SAN JOSE OBRERO"},
    {department: "GUAIRA", city: "VILLARRICA"},
    {department: "GUAIRA", city: "BORJA"},
    {department: "GUAIRA", city: "CAPITAN MAURICIO JOSE TROCHE"},
    {department: "GUAIRA", city: "CORONEL MARTINEZ"},
    {department: "GUAIRA", city: "FELIX PEREZ CARDOZO"},
    {department: "GUAIRA", city: "GRAL. EUGENIO A. GARAY"},
    {department: "GUAIRA", city: "INDEPENDENCIA"},
    {department: "GUAIRA", city: "ITAPE"},
    {department: "GUAIRA", city: "ITURBE"},
    {department: "GUAIRA", city: "JOSE FASSARDI"},
    {department: "GUAIRA", city: "MBOCAYATY"},
    {department: "GUAIRA", city: "NATALICIO TALAVERA"},
    {department: "GUAIRA", city: "ÑUMI"},
    {department: "GUAIRA", city: "SAN SALVADOR"},
    {department: "GUAIRA", city: "YATAITY"},
    {department: "GUAIRA", city: "DOCTOR BOTTRELL"},
    {department: "GUAIRA", city: "PASO YOBAI"},
    {department: "GUAIRA", city: "TEBICUARY"},
    {department: "CAAGUAZU", city: "CORONEL OVIEDO"},
    {department: "CAAGUAZU", city: "CAAGUAZU"},
    {department: "CAAGUAZU", city: "CARAYAO"},
    {department: "CAAGUAZU", city: "DR. CECILIO BAEZ"},
    {department: "CAAGUAZU", city: "SANTA ROSA DEL MBUTUY"},
    {department: "CAAGUAZU", city: "DR. JUAN MANUEL FRUTOS"},
    {department: "CAAGUAZU", city: "REPATRIACION"},
    {department: "CAAGUAZU", city: "NUEVA LONDRES"},
    {department: "CAAGUAZU", city: "SAN JOAQUIN"},
    {department: "CAAGUAZU", city: "SAN JOSE DE LOS ARROYOS"},
    {department: "CAAGUAZU", city: "YHU"},
    {department: "CAAGUAZU", city: "DR. J. EULOGIO ESTIGARRIBIA"},
    {department: "CAAGUAZU", city: "R.I. 3 CORRALES"},
    {department: "CAAGUAZU", city: "RAUL ARSENIO OVIEDO"},
    {department: "CAAGUAZU", city: "JOSE DOMINGO OCAMPOS"},
    {department: "CAAGUAZU", city: "MARISCAL FRANCISCO SOLANO LOPEZ"},
    {department: "CAAGUAZU", city: "LA PASTORA"},
    {department: "CAAGUAZU", city: "3 DE FEBRERO"},
    {department: "CAAGUAZU", city: "SIMON BOLIVAR"},
    {department: "CAAGUAZU", city: "VAQUERIA"},
    {department: "CAAGUAZU", city: "TEMBIAPORA"},
    {department: "CAAGUAZU", city: "NUEVA TOLEDO"},
    {department: "CAAZAPA", city: "CAAZAPA"},
    {department: "CAAZAPA", city: "ABAI"},
    {department: "CAAZAPA", city: "BUENA VISTA"},
    {department: "CAAZAPA", city: "DR. MOISES S. BERTONI"},
    {department: "CAAZAPA", city: "GRAL. HIGINIO MORINIGO"},
    {department: "CAAZAPA", city: "MACIEL"},
    {department: "CAAZAPA", city: "SAN JUAN NEPOMUCENO"},
    {department: "CAAZAPA", city: "TAVAI"},
    {department: "CAAZAPA", city: "YEGROS"},
    {department: "CAAZAPA", city: "YUTY"},
    {department: "CAAZAPA", city: "3 DE MAYO"},
    {department: "ITAPUA", city: "ENCARNACION"},
    {department: "ITAPUA", city: "BELLA VISTA SUR"},
    {department: "ITAPUA", city: "CAMBYRETA"},
    {department: "ITAPUA", city: "CAPITAN MEZA"},
    {department: "ITAPUA", city: "CAPITAN MIRANDA"},
    {department: "ITAPUA", city: "NUEVA ALBORADA"},
    {department: "ITAPUA", city: "CARMEN DEL PARANA"},
    {department: "ITAPUA", city: "CORONEL BOGADO"},
    {department: "ITAPUA", city: "CARLOS ANTONIO LOPEZ"},
    {department: "ITAPUA", city: "NATALIO"},
    {department: "ITAPUA", city: "FRAM"},
    {department: "ITAPUA", city: "GENERAL ARTIGAS"},
    {department: "ITAPUA", city: "GENERAL DELGADO"},
    {department: "ITAPUA", city: "HOHENAU"},
    {department: "ITAPUA", city: "JESUS"},
    {department: "ITAPUA", city: "JOSE LEANDRO OVIEDO"},
    {department: "ITAPUA", city: "OBLIGADO"},
    {department: "ITAPUA", city: "MAYOR JULIO DIONISIO OTAÑO"},
    {department: "ITAPUA", city: "SAN COSME Y DAMIAN"},
    {department: "ITAPUA", city: "SAN PEDRO DEL PARANA"},
    {department: "ITAPUA", city: "SAN RAFAEL DEL PARANA"},
    {department: "ITAPUA", city: "TRINIDAD"},
    {department: "ITAPUA", city: "EDELIRA"},
    {department: "ITAPUA", city: "TOMAS ROMERO PEREIRA"},
    {department: "ITAPUA", city: "ALTO VERA"},
    {department: "ITAPUA", city: "LA PAZ"},
    {department: "ITAPUA", city: "YATYTAY"},
    {department: "ITAPUA", city: "SAN JUAN DEL PARANA"},
    {department: "ITAPUA", city: "PIRAPO"},
    {department: "ITAPUA", city: "ITAPUA POTY"},
    {department: "MISIONES", city: "SAN JUAN BAUTISTA DE LAS MISIONES"},
    {department: "MISIONES", city: "AYOLAS"},
    {department: "MISIONES", city: "SAN IGNACIO"},
    {department: "MISIONES", city: "SAN MIGUEL"},
    {department: "MISIONES", city: "SAN PATRICIO"},
    {department: "MISIONES", city: "SANTA MARIA"},
    {department: "MISIONES", city: "SANTA ROSA"},
    {department: "MISIONES", city: "SANTIAGO"},
    {department: "MISIONES", city: "VILLA FLORIDA"},
    {department: "MISIONES", city: "YABEBYRY"},
    {department: "PARAGUARI", city: "PARAGUARI"},
    {department: "PARAGUARI", city: "ACAHAY"},
    {department: "PARAGUARI", city: "CAAPUCU"},
    {department: "PARAGUARI", city: "CABALLERO"},
    {department: "PARAGUARI", city: "CARAPEGUA"},
    {department: "PARAGUARI", city: "ESCOBAR"},
    {department: "PARAGUARI", city: "LA COLMENA"},
    {department: "PARAGUARI", city: "MBUYAPEY"},
    {department: "PARAGUARI", city: "PIRAYU"},
    {department: "PARAGUARI", city: "QUIINDY"},
    {department: "PARAGUARI", city: "QUYQUYHO"},
    {department: "PARAGUARI", city: "ROQUE GONZALEZ DE SANTA CRUZ"},
    {department: "PARAGUARI", city: "SAPUCAI"},
    {department: "PARAGUARI", city: "TEBICUARY-MI"},
    {department: "PARAGUARI", city: "YAGUARON"},
    {department: "PARAGUARI", city: "YBYCUI"},
    {department: "PARAGUARI", city: "YBYTYMI"},
    {department: "ALTO PARANA", city: "CIUDAD DEL ESTE"},
    {department: "ALTO PARANA", city: "PRESIDENTE FRANCO"},
    {department: "ALTO PARANA", city: "DOMINGO MARTINEZ DE IRALA"},
    {department: "ALTO PARANA", city: "DR. JUAN LEON MALLORQUIN"},
    {department: "ALTO PARANA", city: "HERNANDARIAS"},
    {department: "ALTO PARANA", city: "ITAKYRY"},
    {department: "ALTO PARANA", city: "JUAN E. O'LEARY"},
    {department: "ALTO PARANA", city: "ÑACUNDAY"},
    {department: "ALTO PARANA", city: "YGUAZU"},
    {department: "ALTO PARANA", city: "LOS CEDRALES"},
    {department: "ALTO PARANA", city: "MINGA GUAZU"},
    {department: "ALTO PARANA", city: "SAN CRISTOBAL"},
    {department: "ALTO PARANA", city: "SANTA RITA"},
    {department: "ALTO PARANA", city: "NARANJAL"},
    {department: "ALTO PARANA", city: "SANTA ROSA DEL MONDAY"},
    {department: "ALTO PARANA", city: "MINGA PORA"},
    {department: "ALTO PARANA", city: "MBARACAYU"},
    {department: "ALTO PARANA", city: "SAN ALBERTO"},
    {department: "ALTO PARANA", city: "IRUÑA"},
    {department: "ALTO PARANA", city: "SANTA FE DEL PARANA"},
    {department: "ALTO PARANA", city: "TAVAPY"},
    {department: "ALTO PARANA", city: "DR. RAUL PEÑA"},
    {department: "CENTRAL", city: "AREGUA"},
    {department: "CENTRAL", city: "CAPIATA"},
    {department: "CENTRAL", city: "FERNANDO DE LA MORA"},
    {department: "CENTRAL", city: "GUARAMBARE"},
    {department: "CENTRAL", city: "ITA"},
    {department: "CENTRAL", city: "ITAUGUA"},
    {department: "CENTRAL", city: "LAMBARE"},
    {department: "CENTRAL", city: "LIMPIO"},
    {department: "CENTRAL", city: "LUQUE"},
    {department: "CENTRAL", city: "MARIANO ROQUE ALONSO"},
    {department: "CENTRAL", city: "NUEVA ITALIA"},
    {department: "CENTRAL", city: "ÑEMBY"},
    {department: "CENTRAL", city: "SAN ANTONIO"},
    {department: "CENTRAL", city: "SAN LORENZO"},
    {department: "CENTRAL", city: "VILLA ELISA"},
    {department: "CENTRAL", city: "VILLETA"},
    {department: "CENTRAL", city: "YPACARAI"},
    {department: "CENTRAL", city: "YPANE"},
    {department: "CENTRAL", city: "J. AUGUSTO SALDIVAR"},
    {department: "ÑEEMBUCU", city: "PILAR"},
    {department: "ÑEEMBUCU", city: "ALBERDI"},
    {department: "ÑEEMBUCU", city: "CERRITO"},
    {department: "ÑEEMBUCU", city: "DESMOCHADOS"},
    {department: "ÑEEMBUCU", city: "GRAL. JOSE EDUVIGIS DIAZ"},
    {department: "ÑEEMBUCU", city: "GUAZU-CUA"},
    {department: "ÑEEMBUCU", city: "HUMAITA"},
    {department: "ÑEEMBUCU", city: "ISLA UMBU"},
    {department: "ÑEEMBUCU", city: "LAURELES"},
    {department: "ÑEEMBUCU", city: "MAYOR JOSE DEJESUS MARTINEZ"},
    {department: "ÑEEMBUCU", city: "PASO DE PATRIA"},
    {department: "ÑEEMBUCU", city: "SAN JUAN BAUTISTA DE ÑEEMBUCU"},
    {department: "ÑEEMBUCU", city: "TACUARAS"},
    {department: "ÑEEMBUCU", city: "VILLA FRANCA"},
    {department: "ÑEEMBUCU", city: "VILLA OLIVA"},
    {department: "ÑEEMBUCU", city: "VILLALBIN"},
    {department: "AMAMBAY", city: "PEDRO JUAN CABALLERO"},
    {department: "AMAMBAY", city: "BELLA VISTA NORTE"},
    {department: "AMAMBAY", city: "CAPITAN BADO"},
    {department: "AMAMBAY", city: "ZANJA PYTÃ"},
    {department: "AMAMBAY", city: "KARAPAI"},
    {department: "CANINDEYU", city: "SALTO DEL GUAIRA"},
    {department: "CANINDEYU", city: "CORPUS CHRISTI"},
    {department: "CANINDEYU", city: "VILLA CURUGUATY"},
    {department: "CANINDEYU", city: "VILLA YGATIMI"},
    {department: "CANINDEYU", city: "ITANARA"},
    {department: "CANINDEYU", city: "YPEJHU"},
    {department: "CANINDEYU", city: "FRANCISCO CABALLERO ALVAREZ"},
    {department: "CANINDEYU", city: "KATUETE"},
    {department: "CANINDEYU", city: "LA PALOMA DEL ESPIRITU SANTO"},
    {department: "CANINDEYU", city: "NUEVA ESPERANZA"},
    {department: "CANINDEYU", city: "YASY CAÑY"},
    {department: "CANINDEYU", city: "YBYRAROBANA"},
    {department: "CANINDEYU", city: "YBY PYTA"},
    {department: "PRESIDENTE HAYES", city: "BENJAMIN ACEVAL"},
    {department: "PRESIDENTE HAYES", city: "PUERTO PINASCO"},
    {department: "PRESIDENTE HAYES", city: "VILLA HAYES"},
    {department: "PRESIDENTE HAYES", city: "NANAWA"},
    {department: "PRESIDENTE HAYES", city: "JOSE FALCON"},
    {department: "PRESIDENTE HAYES", city: "TTE. 1° MANUEL IRALA FERNANDEZ"},
    {department: "PRESIDENTE HAYES", city: "TENIENTE ESTEBAN MARTINEZ"},
    {department: "PRESIDENTE HAYES", city: "GENERAL JOSE MARIA BRUGUEZ"},
    {department: "BOQUERON", city: "MARISCAL JOSE FELIX ESTIGARRIBIA"},
    {department: "BOQUERON", city: "FILADELFIA"},
    {department: "BOQUERON", city: "LOMA PLATA"},
    {department: "ALTO PARAGUAY", city: "FUERTE OLIMPO"},
    {department: "ALTO PARAGUAY", city: "PUERTO CASADO"},
    {department: "ALTO PARAGUAY", city: "BAHIA NEGRA"},
    {department: "ALTO PARAGUAY", city: "CARMELO PERALTA"},
    {department: "CONCEPCION", city: "OTRO DISTRITO"},
    {department: "SAN PEDRO", city: "OTRO DISTRITO"},
    {department: "CORDILLERA", city: "OTRO DISTRITO"},
    {department: "GUAIRA", city: "OTRO DISTRITO"},
    {department: "CAAGUAZU", city: "OTRO DISTRITO"},
    {department: "CAAZAPA", city: "OTRO DISTRITO"},
    {department: "ITAPUA", city: "OTRO DISTRITO"},
    {department: "MISIONES", city: "OTRO DISTRITO"},
    {department: "PARAGUARI", city: "OTRO DISTRITO"},
    {department: "ALTO PARANA", city: "OTRO DISTRITO"},
    {department: "CENTRAL", city: "OTRO DISTRITO"},
    {department: "ÑEEMBUCU", city: "OTRO DISTRITO"},
    {department: "CANINDEYU", city: "OTRO DISTRITO"},
    {department: "AMAMBAY", city: "OTRO DISTRITO"},
    {department: "PRESIDENTE HAYES", city: "OTRO DISTRITO"},
    {department: "BOQUERON", city: "OTRO DISTRITO"},
    {department: "ALTO PARAGUAY", city: "OTRO DISTRITO"},
    {department: "ARGENTINA", city: "FORMOSA"}]
    .map((c, idx) => ({id: idx, ...c}));
