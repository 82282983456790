import {WidgetProps} from "@rjsf/core";
import {TimePicker} from "antd";
import dayjs from "dayjs";

const DATE_PICKER_STYLE = {
    width: '100%',
};

export function TimeWidget({
                               formContext,
                               onBlur,
                               onFocus,
                               id,
                               value,
                               disabled,
                               readonly,
                               placeholder,
                               onChange
                           }: WidgetProps) {
    const {readonlyAsDisabled = true} = formContext;

    const handleChange = (nextValue: any) => {
        onChange(nextValue && nextValue.format('HH:mm'));
    }

    const handleBlur = () => onBlur(id, value);

    const handleFocus = () => onFocus(id, value);

    return <TimePicker
        disabled={disabled || (readonlyAsDisabled && readonly)}
        id={id}
        name={id}
        onBlur={!readonly ? handleBlur : undefined}
        onChange={!readonly ? handleChange : undefined}
        onFocus={!readonly ? handleFocus : undefined}
        placeholder={placeholder}
        style={DATE_PICKER_STYLE}
        format="HH:mm"
        showSecond={false}
        value={value && dayjs(value, 'HH:mm')}
    />
}
