/// <reference types="@types/google.maps" />
import {
    AddAdminRequest,
    AddGroupData,
    ApiGroup,
    InvalidRequest,
    LoginResponse,
    ReportRequest,
    ResetAdminPass,
    ResetResponse,
    TableRequest,
    TableResponse,
    UpdateEndUser
} from "./Model";
import config from './Config';
import {t} from "./Messages";

export class Api {

    constructor(private token?: string, private onUnauthorized?: () => void) {
    }

    login(username: string, password: string): Promise<LoginResponse> {
        return this.doPost(`login`, {username, password});
    }

    logout(): Promise<void> {
        return this.doGet(`logout`);
    }

    groups(): Promise<ApiGroup[]> {
        return this.doGet(`group`);
    }

    addGroup(data: AddGroupData): Promise<ApiGroup> {
        return this.doPost(`group`, data);
    }

    report(name: string, params: ReportRequest): Promise<any> {
        return this.doPost(`report/${name}`, params)
    }

    dynamicTable<T>(name: string, params: TableRequest): Promise<TableResponse<T>> {
        return this.doPost(`table/${name}`, params)
    }

    dynamicTableDownload(name: string, params: TableRequest): Promise<Blob> {
        return this.getBlob(`table/${name}/export`, 'POST', params);
    }

    resetPin(document: number): Promise<ResetResponse> {
        return this.doPost(`user/reset?${this.encode({document})}`, {});
    }

    async uploadImage(group: string, file: File): Promise<string> {

        const headers: HeadersInit = {};
        if (this.token) {
            headers['AUTHORIZATION'] = `Bearer ${this.token}`;
        }
        const formData = new FormData();
        formData.append("files", file, file.name);

        const response = await fetch(`${config.apiUrl}/groups/${group}/image`, {
            method: 'POST',
            headers: headers,
            body: formData
        });
        return this.handleResponse(response, "");
    }

    async googleReverseGeoCoding(lat: number, lng: number): Promise<google.maps.GeocoderResponse> {

        const params = this.encode({
            key: config.googleApiKey,
            sensor: true,
            latlng: `${lat},${lng}`
        })
        const d = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?${params}`);
        if (d.ok)
            return d.json();

        const body = await d.text();
        throw new ApiError(d.statusText, d.status, body);
    }

    addAdmin(req: AddAdminRequest): Promise<{ msg: string }> {
        return this.doPost('admin', req);
    }

    updateAdmin(id: number, req: AddAdminRequest): Promise<{ msg: string }> {
        return this.doPost(`admin/${id}`, req);
    }

    resetAdminPass(id: number, req: ResetAdminPass): Promise<{ msg: string }> {
        return this.doPost(`admin/${id}/resetPass`, req);
    }

    addCampaign(req: { name: string }): Promise<{ msg: string }> {
        return this.doPost(`campaign/`, req);
    }

    updateUser(id: number, req: UpdateEndUser) {
        return this.doPost(`end_user/${id}/`, req);
    }

    setSupervisor(group_id: number, supervisorId: number) {
        return this.doPost(`group/${group_id}/supervisor`, {supervisorId});
    }

    removeSupervisor(group_id: number) {
        return this.doDelete(`group/${group_id}/supervisor`);
    }

    private async doGet<T>(url: string): Promise<T> {
        const headers: HeadersInit = {};
        if (this.token) {
            headers['AUTHORIZATION'] = `Bearer ${this.token}`;
        }

        const response = await fetch(`${config.apiUrl}/${url}`, {headers});
        return this.handleResponse(response);
    }

    private async handleResponse<T>(d: Response, def?: T): Promise<T> {

        if (d.ok) {
            // no content
            if (d.status === 204 && def !== undefined) {
                return Promise.resolve(def);
            }
            return d.json();
        }

        if (d.status === 401 || d.status === 403)
            this.onUnauthorized?.()

        const body = await d.text();
        throw new ApiError(d.statusText, d.status, body);
    }

    private async doPost<T>(url: string, requestBody: unknown): Promise<T> {

        const headers: HeadersInit = {
            'Content-type': 'application/json'
        };
        if (this.token) {
            headers['AUTHORIZATION'] = `Bearer ${this.token}`;
        }
        const d = await fetch(`${config.apiUrl}/${url}`, {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers
        })

        return this.handleResponse(d);
    }

    private async doDelete<T>(url: string, requestBody?: unknown): Promise<T> {

        const headers: HeadersInit = {
            'Content-type': 'application/json'
        };
        if (this.token) {
            headers['AUTHORIZATION'] = `Bearer ${this.token}`;
        }
        const d = await fetch(`${config.apiUrl}/${url}`, {
            method: 'DELETE',
            body: requestBody ? JSON.stringify(requestBody) : undefined,
            headers
        })

        return this.handleResponse(d);
    }

    private encode(params: Record<string, unknown>): string {
        const toEncode = new URLSearchParams()
        Object.entries(params).forEach(([k, v]) => toEncode.append(k, v + ""))
        return toEncode.toString();
    }

    async getBlob(src: string, method: 'GET' | 'POST', requestBody?: unknown): Promise<Blob> {

        const headers: HeadersInit = {
            'Content-type': 'application/json',
            'AUTHORIZATION': `Bearer ${this.token}`,
        };

        const d = await fetch(`${config.apiUrl}/${src}`, {
            headers: headers,
            method: method,
            body: requestBody ? JSON.stringify(requestBody) : undefined,
        })

        if (d.ok) {
            return d.blob();
        }

        if (d.status === 401 || d.status === 403)
            this.onUnauthorized?.()

        const body = await d.text();
        throw new ApiError(d.statusText, d.status, body);
    }

    static getGroupImageUrl(group: string): string {
        return `groups/${group}/image`;
    }

}


export function getMessage(err: Error, def: string): string {
    if (err instanceof ApiError) {
        return err.getMsg() || def;
    }
    return def;
}

export class ApiError extends Error {
    constructor(msg: string, private code: number, private body: string) {
        super(msg);
    }

    getJson() {
        return JSON.stringify(this.body || '');
    }

    getText() {
        return this.body;
    }

    getCode() {
        return this.code;
    }

    asInvalidRequest(): InvalidRequest | undefined {
        const json = JSON.parse(this.body);
        if (this.code === 400 && json.msg === 'invalid.request') {
            return json as InvalidRequest;
        }
        return undefined;
    }

    getMsg(): string | undefined {
        const json = JSON.parse(this.body);
        if ('msg' in json) return t(json.msg) as string;
        return undefined;
    }

    asSimpleCode() {
        if (this.code === 404) return 404;
        if (this.code === 403) return 403;
        return 500;
    }
}
