import React, {useCallback, useEffect, useMemo, useState} from "react";
import {AddGroupData, ApiGroup, Async, AsyncHelper} from "../../Model";
import {Button, Form as AntdForm, Input, Layout, message, Modal, Select, Space, Upload} from "antd";
import {
    Form,
    GroupEditData,
    SCHEMA,
    translateErrors,
    UI_SCHEMA,
    WIDGETS
} from "../../components/group/GroupCreationForm";
import {ObjectFieldTemplate} from "../../components/react-schema-form/ObjectFieldTemplate";
import {ArrayFieldTemplate} from "../../components/react-schema-form/ArrayFieldTemplate";
import {useUser} from "../../AuthContext";
import './AddGroupMobilePage.less';
import {useHistory} from "react-router-dom";
import {ISubmitEvent} from "@rjsf/core";
import {ApiError} from "../../Api";
import {extractMsg} from "../../util/ExceptionHelper";
import {t} from "../../Messages";
import {Cities} from "../../util/GeoData";
import {CampaignDTable} from "../CampaignPage";
import {AdminDTable} from "../AdminsPage";
import {RcFile} from "antd/es/upload";
import {UploadRequestOption as RcCustomRequestOptions} from "rc-upload/lib/interface";

export function AddGroupMobilePage() {


    const [step, setStep] = useState('meta');
    const [step1Result, setStep1Result] = useState<MetaData>()
    const [step2Result, setStep2Result] = useState<ApiGroup>();
    const {api} = useUser();
    const [working, setWorking] = useState(false);

    function onSubmit(toAdd: GroupEditData) {
        if (!step1Result) return;

        setWorking(true);
        api.addGroup(mapToApi(toAdd, step1Result))
            .then(d => {
                setWorking(false);
                setStep2Result(d);
                setStep('image');
                message.success({key: 'add-group', content: `Grupo ${toAdd.name} guardado`, duration: 5});
            })
            .catch(e => {
                setWorking(false);
                console.warn(e);
                let msg = 'Error al guardar grupo';
                if (e instanceof ApiError) {
                    console.warn('is api', e.getJson());
                    const validation = e.asInvalidRequest();
                    if (validation) {
                        msg = extractMsg(validation);
                    }
                    const eMsg = e.getMsg();
                    if (eMsg) {
                        msg = t(eMsg);
                    }
                }
                message.error({key: 'add-group', content: msg, duration: 20});
            });
    }


    function onFinishStep1(info: MetaData) {
        setStep1Result(info);
        setStep('data');
    }

    return <div className="add-group-mobile-page">
        {step === 'meta' && <Step1Metadata onNext={onFinishStep1}/>}
        {step === 'data' && step1Result && <Step2GroupData onSubmit={onSubmit} working={working}/>}
        {step === 'image' && step2Result && <Step3AddImage info={step2Result}/>}
    </div>
}


function Step1Metadata({onNext}: {
    onNext: (dat: MetaData) => void
}) {

    const {user, api} = useUser();


    const [department, setDepartment] = useState<string>();
    const [city, setCity] = useState<string>();

    const departments = useMemo(() => Array.from(new Set(Cities.map(c => c.department))), []);
    const cities = useMemo(() => {
        return Cities.filter(c => c.department === department).map(c => c.city);
    }, [department]);

    const canSelectCampaign = user.role === 'ADMIN' || user.role === 'SUPERVISOR';
    const canSelectSupervisor = user.role === 'ADMIN' || user.role === 'MANAGER';

    const [campaign, setCampaign] = useState<number>();
    const [campaigns, setCampaigns] = useState<{ id: number, name: string }[]>([]);
    const [supervisor, setSupervisor] = useState<number>();
    const [supervisors, setSupervisors] = useState<{ id: number, name: string }[]>([]);

    useEffect(() => {
        if (!canSelectCampaign) {
            setCampaign(user.campaign.id || 1000);
            return;
        }
        api.dynamicTable<CampaignDTable>('campaigns', {
            offset: 0,
            limit: 1000
        }).then(cs => setCampaigns(cs.rows.map(c => ({id: c.campaign_id, name: c.name}))));
    }, [api, canSelectCampaign, user]);

    useEffect(() => {
        if (!canSelectSupervisor) {
            setSupervisor(-1000);
            setSupervisors([{id: -1000, name: user.name}])
            return;
        }
        api.dynamicTable<AdminDTable>('admins', {
            offset: 0,
            limit: 1000,
            params: {
                role: 'SUPERVISOR'
            }
        }).then(cs => {
            setSupervisors(cs.rows.map(c => ({id: c.id, name: c.name})))
        });
    }, [api, canSelectSupervisor, campaign, user.name]);

    const handleSubmit = useCallback(() => {
        const finalCampaign = canSelectCampaign ? campaign : user.campaign.id;
        const finalSupervisor = canSelectSupervisor ? supervisor : -1000; // doesnt matter, the back change this to the real value
        if (!finalCampaign || !finalSupervisor || !city || !department) {
            message.warn("Selecciona un campaña, facilitador, ciudad y departamento");
            return;
        }
        onNext({
            city: city,
            department: department,
            campaign: finalCampaign,
            supervisor: finalSupervisor
        })
    }, [onNext, canSelectSupervisor, canSelectCampaign, campaign, supervisor, city, department, user.campaign.id])

    return <>
        <div className="welcome">
            <b>{user.name}</b>, para crear un nuevo grupo, primero es necesario
            saber información acerca de la ubicación, campaña y facilitador.
        </div>
        <AntdForm style={{textAlign: 'center'}} layout="vertical">
            <AntdForm.Item name="campaign"
                           label="Campaña"
                           rules={[{required: true, message: 'Campaña requerida'}]}>
                {canSelectCampaign
                    ? <Select style={{width: '100%'}}
                              value={campaign}
                              onChange={c => setCampaign(c as number)}>
                        {campaigns.map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                    </Select>
                    : <Input disabled={true}
                             placeholder={user.campaign.name}
                             value={user.campaign.name}/>
                }
            </AntdForm.Item>
            <AntdForm.Item name="supervisor"
                           label="Facilitador"
                           rules={[{required: true, message: 'Facilitador requerido'}]}>
                {canSelectSupervisor
                    ? <Select style={{width: '100%'}}
                              value={supervisor}
                              onChange={c => setSupervisor(c as number)}>
                        {supervisors.map(c => <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>)}
                    </Select>
                    : <Input disabled={true}
                             placeholder={user.name}
                             value={user.name}/>
                }
            </AntdForm.Item>
            <AntdForm.Item name="department"
                           label="Departamento"
                           rules={[{required: true, message: 'Departamento requerido'}]}>
                <Select style={{width: '100%'}}
                        value={department}
                        onChange={c => {
                            if (!c) return;
                            const dep = c as string;
                            setDepartment(dep);
                            if (city)
                                setCity(Cities.find(c => c.department === dep)?.city || Cities[0].city);
                        }}>
                    {departments.map(c => <Select.Option key={c} value={c}>{c}</Select.Option>)}
                </Select>
            </AntdForm.Item>
            <AntdForm.Item name="city"
                           label="Ciudad"
                           rules={[{required: true, message: 'Ciudad requerida'}]}>
                <Select style={{width: '100%'}}
                        value={city}
                        onChange={c => setCity(c as string)}>
                    {cities.map(c => <Select.Option key={c} value={c}>{c}</Select.Option>)}
                </Select>
            </AntdForm.Item>
            <AntdForm.Item>
                <Button type="primary"
                        onClick={handleSubmit}
                        className="mobile-button">
                    Siguiente
                </Button>
            </AntdForm.Item>
        </AntdForm>
    </>
}


function Step2GroupData(props: {
    onSubmit: (t: GroupEditData) => void;
    working: boolean;
}) {

    const [formData, setFormData] = useState<Partial<GroupEditData>>(BASE_GROUP_DATA);

    function onSubmit(dat: ISubmitEvent<GroupEditData>) {
        setFormData(dat.formData);
        props.onSubmit(dat.formData);
    }

    return <Layout>
        <Layout.Content className="content">
            <Form schema={SCHEMA}
                  uiSchema={UI_SCHEMA}
                  ObjectFieldTemplate={ObjectFieldTemplate}
                  ArrayFieldTemplate={ArrayFieldTemplate}
                  formData={formData}
                  noHtml5Validate
                  widgets={WIDGETS}
                  showErrorList={false}
                  transformErrors={translateErrors}
                  liveValidate
                  className="add-group-form"
                  onSubmit={onSubmit}>
                <Button htmlType="submit"
                        type="primary"
                        className="mobile-button"
                        loading={props.working}
                        block
                        disabled={false}>
                    {props.working ? t('generic.saving') : t('generic.save')}
                </Button>
            </Form>
        </Layout.Content>
    </Layout>
}

function Step3AddImage(props: {
    info: ApiGroup;
}) {

    const history = useHistory();

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    return <div>
        <div className="welcome">
            El grupo ya ha sido creado, las socias ya pueden iniciar sesión.
            <br/>
            Como último paso puedes cargar la foto del reglamento.
            <br/>
            Si todavía no cuentas con el reglamento, puedes enviar la foto mas tarde a la fundación para que
            sea cargada.
        </div>
        <Space>
            <UploadImage groupId={props.info.id} onSuccess={() => {
                message.success({content: 'Imagen subida exitosamente', key: 'image-upload', duration: 10});
                setShowSuccessMessage(true);
            }}/>
        </Space>
        <Modal afterClose={() => {
            setShowSuccessMessage(false);
            setTimeout(() => history.push('/'), 1000);
        }}
               onOk={() => setShowSuccessMessage(false)}
               onCancel={() => setShowSuccessMessage(false)}
               visible={showSuccessMessage}
               okText="Aceptar"
               cancelButtonProps={{style: {display: 'none'}}}
        >
            Foto del grupo cargada exitosamente!.

            Las socias ya pueden iniciar sesión.
        </Modal>
    </div>
}

export function UploadImage(props: {
    groupId: string;
    onSuccess: () => void;
}) {
    const [preview, setPreview] = useState<Async<string>>(AsyncHelper.noRequested());

    function beforeUpload(file: RcFile) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error({content: t('invalid.image.type'), key: 'image-upload', duration: 5});
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error({content: t('invalid.image.size'), key: 'image-upload', duration: 5});
        }
        return isJpgOrPng && isLt2M;
    }

    const {api} = useUser();

    function load(data: RcCustomRequestOptions) {
        if (!beforeUpload(data.file as RcFile)) {
            return;
        }
        data.onProgress?.({percent: 0} as any);
        api.uploadImage(props.groupId, data.file as RcFile)
            .then(() => {
                data.onProgress?.({percent: 100} as any);
                data.onSuccess?.('', null as any);
                setPreview(AsyncHelper.loaded('ok'));
                props.onSuccess();
            })
            .catch(e => {
                data.onError?.({percent: 100} as any, e.message);
                console.log(e);
                setPreview(AsyncHelper.error(e));
            });
    }

    return <Upload customRequest={load} beforeUpload={beforeUpload} listType="picture">
        <Button type="primary"
                loading={preview.state === 'FETCHING'}
                className="mobile-button">
            {btnUploadMsg(preview)}
        </Button>
    </Upload>
}

function btnUploadMsg(dat: Async<unknown>) {
    switch (dat.state) {
        case "NO_REQUESTED":
            return "Subir foto de reglamento";
        case "ERROR":
            return "Error al subir, volver a intentar";
        case "LOADED":
            return "Foto subida!";
        case "FETCHING":
            return "Subiendo";
    }
}

interface MetaData {
    city: string;
    campaign: number;
    department: string;
    supervisor: number;
}

export function mapToApi(data: GroupEditData, geo: MetaData): AddGroupData {
    return {
        city: (geo.city || '').toUpperCase(),
        department: (geo.department || '').toUpperCase(),
        members: data.members,
        penalties: data.penalties,
        actionPrice: data.actionPrice,
        location: (geo.city || '').toUpperCase(),
        name: data.name,
        lat: 0,
        lng: 0,
        meetingDay: data.meetingDay,
        meetingHour: data.meetingHour,
        every: data.periodicity,
        maxShares: data.maxShares,
        sharedFoundAmount: data.sharedFoundAmount,

        supervisor: geo.supervisor,
        campaign: geo.campaign,

        endDate: data.endDate
    };
}


const prefix: number = +new Date();
const BASE_GROUP_DATA: any = process.env.NODE_ENV === 'production'
    ? {}
    : {
        "city": Cities[0].city,
        "department": Cities[0].department,
        "members": [
            {
                "gender": "F",
                "name": "11",
                "lastName": "11",
                "document": `${prefix}11`,
                "birthDate": "1970-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "22",
                "lastName": "22",
                "document": `${prefix}22`,
                "birthDate": "1980-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "33",
                "lastName": "33",
                "document": `${prefix}33`,
                "birthDate": "1990-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "44",
                "lastName": "44",
                "document": `${prefix}44`,
                "birthDate": "2000-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "55",
                "lastName": "55",
                "document": `${prefix}55`,
                "birthDate": "2010-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "66",
                "lastName": "66",
                "document": `${prefix}66`,
                "birthDate": "1975-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "77",
                "lastName": "77",
                "document": `${prefix}77`,
                "birthDate": "1985-02-02",
                "phone": "123456789"
            },
            {
                "gender": "F",
                "name": "88",
                "lastName": "88",
                "document": `${prefix}88`,
                "birthDate": "1995-02-02",
                "phone": "123456789"
            }
        ],
        "penalties": [
            {
                "name": "Usar celular durante la reunión",
                "amount": 2000
            },
            {
                "name": "Llegada tardía",
                "amount": 5000
            },
            {
                "name": "Llegada super tardia",
                "amount": 10000
            },
            {
                "name": "Ausencia reuion anterior",
                "amount": 5000
            }
        ],
        "actionPrice": 5000,
        "location": "Cañada Garay",
        "name": "Grupo prueba",
        "lat": -25.2583373,
        "lng": -57.4689363,
        "meetingDay": "MONDAY",
        "meetingHour": "20:00",
        "every": 2
    }
