import {BasePage} from "../components/layout/BasePage";
import {Button, Col, Descriptions, message, Modal, Row, Table} from "antd";
import {usePaginatedTable} from "../hooks/usePaginatedTable";
import React, {useCallback, useEffect, useState} from "react";
import {useFilter} from "../FilterContext";
import {role, t} from "../Messages";
import {formatDate, formatDateTime, formatMoney, miliSecondsToDate} from "../formatter";
import {useUser} from "../AuthContext";
import {JSONSchema7} from "json-schema";
import {Form, translateErrors, WIDGETS} from "../components/group/GroupCreationForm";
import {ObjectFieldTemplate} from "../components/react-schema-form/ObjectFieldTemplate";
import {ArrayFieldTemplate} from "../components/react-schema-form/ArrayFieldTemplate";
import {ISubmitEvent} from "@rjsf/core";
import {UpdateEndUser} from "../Model";
import {getMessage} from "../Api";
import {ArrowLeftOutlined} from "@ant-design/icons";

interface GoalDTO {
    id: number;
    category: string;
    required: number;
    description: string;
}

interface LastGroupDTO {
    id: number;
    name: string;
    number: number;
    end_date: string | null;
}

interface GroupDTO {
    id: number;
    name: string;
    number: number;
    cycle: number;
    goals: Array<GoalDTO>;
    share_price: number;
    total_shares_bought: number;
}

export interface DTFullEndUser {
    birth_date: string;
    last_login: string;
    gender: string;
    phone: string;
    stats: Array<{
        kind: string,
        count: number
    }>,
    document: string;
    name: string;
    last_name: string;
    created_at: string;
    last_group: LastGroupDTO | null,
    groups: Array<GroupDTO> | null,
    id: number,
    totals: {
        fee_amount: number;
        loan_amount: number;
        shares_amount: number;
        social_amount: number;
        meeting_avg_duration: number;
        meeting_total_duration: number;
    } | null,
    age: number,

    avg_monthly_savings: number;

    group_city: never;
    group_department: never;
    campaign: never;
    group: never;


}

export function EndUserPage(props: {
    id: number
}) {
    const data = usePaginatedTable<DTFullEndUser>('end_user', {
        filter: {id: props.id}
    }, false);
    const {filter} = useFilter();

    useEffect(() => {
        data.updateFilters({
            id: props.id,
            group_city: filter.city,
            group_department: filter.department,
            campaign: filter.campaign,
            group: filter.group,
        })
        // eslint-disable-next-line
    }, [filter, props.id]);


    if (data.isLoading) {
        return <div>Cargando...</div>
    }

    if (data.hasError) {
        return <div>Error, contacte con el administrador.</div>
    }

    const rows = data.rows;
    if (rows.length < 1) {
        return <div>Datos no encontrados</div>
    }
    const row = rows[0];

    return <BasePage>
        <Row gutter={[24, 24]}>
            <Col xs={24} style={{background: 'white'}}>
                <div style={{padding: 10, textAlign: 'left'}}>
                    <EndUserPanel data={row} update={data.refresh}/>
                </div>
            </Col>
        </Row>
    </BasePage>
}

function EndUserPanel({data, update}: { data: DTFullEndUser, update: () => void }) {

    const [toEdit, setToEdit] = useState<DTFullEndUser>();
    const {user} = useUser();
    const isSupervisor = user.role === 'SUPERVISOR';


    function goEdit() {
        setToEdit(data);
    }

    function onEdit() {
        setToEdit(undefined);
        update();
    }

    return <>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
            <button onClick={() => window.history.back()} className="link-button">
                <ArrowLeftOutlined/>
            </button>
            <h2>
                Usuario: {data.name} {data.last_name}
            </h2>
            <Button style={{marginLeft: 10}} onClick={goEdit}>
                Editar
            </Button>
        </div>
        <Descriptions bordered column={2}>
            <Descriptions.Item label="Cédula">{data.document}</Descriptions.Item>
            <Descriptions.Item label="Ultimo inicio de sesión">{formatDateTime(data.last_login)}</Descriptions.Item>
            <Descriptions.Item label="Teléfono">{data.phone}</Descriptions.Item>
            <Descriptions.Item label="Tiempo promedio por sesión">
                {miliSecondsToDate(data.totals?.meeting_avg_duration)}
            </Descriptions.Item>
            <Descriptions.Item label="Sexo">{t(data.gender)}</Descriptions.Item>
            <Descriptions.Item label="Tiempo total en sesiones">
                {miliSecondsToDate(data.totals?.meeting_total_duration)}
            </Descriptions.Item>
            <Descriptions.Item label="Edad">{data.age}</Descriptions.Item>
            <Descriptions.Item label="Cantidad de sesiones iniciadas">
                {getAmount('LOGIN', data.stats)}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha nacimiento">{formatDateTime(data.birth_date)}</Descriptions.Item>
            <Descriptions.Item label="Cantidad de ingresos a mis metas">
                {getAmount('VIEW_GOALS', data.stats)}
            </Descriptions.Item>
            <Descriptions.Item label="Grupo">
                <LastGroupRender group={data.last_group}/>
            </Descriptions.Item>
            <Descriptions.Item label="Cantidad de solicitudes de reset de PIN">
                {getAmount('RESET_PIN', data.stats)}
            </Descriptions.Item>
            <Descriptions.Item label="Rol actual">
                {role(data.last_group?.number)}
            </Descriptions.Item>
            {isSupervisor
                ? null
                : <Descriptions.Item label="Total acciones compradas">
                    {formatMoney(data.totals?.shares_amount)}
                </Descriptions.Item>
            }
            <Descriptions.Item label="Fecha creación">{formatDateTime(data.created_at)}</Descriptions.Item>
            {isSupervisor ? null
                : <Descriptions.Item label="Total aporte a fondo social">
                    {formatMoney(data.totals?.social_amount)}
                </Descriptions.Item>
            }

            {isSupervisor ? null
                : <Descriptions.Item label="">
                </Descriptions.Item>
            }


            {isSupervisor
                ? null
                : <Descriptions.Item label="Total multas">
                    {formatMoney(data.totals?.fee_amount)}
                </Descriptions.Item>
            }

            {isSupervisor ? null
                : <Descriptions.Item label="">
                </Descriptions.Item>
            }

            {isSupervisor
                ? null
                : <Descriptions.Item label="Promedio de ahorro mensual">
                    {formatMoney(data.avg_monthly_savings)}
                </Descriptions.Item>
            }

        </Descriptions>
        <h2 style={{textAlign: 'center'}}>
            Histórico de grupos
        </h2>
        <Table
            dataSource={data.groups || []}
            rowKey="id"
            locale={{
                emptyText: 'Sin datos según filtro'
            }}
            columns={[
                {dataIndex: 'name', title: 'Nombre'},
                {dataIndex: 'cycle', title: 'Ciclo'},
                {dataIndex: 'number', title: 'Rol', render: role},
                {dataIndex: '', title: 'Metas', width: 500, render: (_, r) => <GoalsWithProgress data={r}/>},
            ]}/>

        {toEdit && <EditUser data={toEdit} onComplete={onEdit} visible={true}/>}

    </>
}

export function EditUser({data, onComplete, visible}: {
    visible: boolean,
    data: DTFullEndUser,
    onComplete: () => void
}) {

    const [working, setWorking] = useState(false);
    const {api} = useUser();

    const handleSubmit = useCallback((ev: ISubmitEvent<UpdateEndUser>) => {
        setWorking(true);
        api.updateUser(data.id, ev.formData).then(() => {
            message.success({
                content: `Usuario '${ev.formData.name}' guardado`,
                key: 'edit-user',
                duration: 5
            });
            setWorking(false);
            onComplete();
        }).catch(e => {
            console.error(e);
            setWorking(false);
            message.error({content: getMessage(e, 'Error al guardar usuario'), key: 'edit-user', duration: 5});
        });
    }, [onComplete, api, data.id])

    const prevData = {
        ...data,
        name: data.name,
        lastName: data.last_name,
        birthDate: formatDate(data.birth_date)
    }

    return <Modal visible={visible}
                  okButtonProps={{style: {display: 'none'}}}
                  cancelButtonProps={{style: {display: 'none'}}}
                  okText={working ? t('generic.saving') : t('generic.edit')}
                  title="Editando usuario"
                  onCancel={onComplete}>

        <Form schema={EndUserSchema}
              uiSchema={EndUserUISchema}
              ObjectFieldTemplate={ObjectFieldTemplate}
              ArrayFieldTemplate={ArrayFieldTemplate}
              formData={prevData}
              noHtml5Validate
              widgets={WIDGETS}
              showErrorList={false}
              transformErrors={translateErrors}
              liveValidate
              className="add-group-form"
              onSubmit={handleSubmit}>
            <Button htmlType="submit"
                    type="primary"
                    loading={working}
                    block
                    disabled={false}>
                {working ? t('generic.saving') : t('generic.save')}
            </Button>
            <Button loading={working}
                    block
                    onClick={onComplete}
                    disabled={false}>
                {working ? t('generic.saving') : t('generic.cancel')}
            </Button>
        </Form>
    </Modal>
}

const EndUserSchema: JSONSchema7 = {
    type: 'object',
    title: '',
    properties: {
        name: {type: 'string', title: 'Nombre'},
        lastName: {type: 'string', title: 'Apellido'},
        document: {type: 'string', title: 'Cédula'},
        gender: {
            type: 'string',
            title: 'Sexo',
            enum: ['F', 'M'],
            enumNames: [t('F'), t('M')],
            default: 'F'
        } as any,
        birthDate: {type: 'string', title: 'Fecha de Nacimiento', format: 'date'},
        phone: {type: 'string', title: 'Teléfono'},
    },
    required: ["name", "lastName", "document", "gender", "birthDate", "phone"]
}

const EndUserUISchema = {
    name: {xs: 12, md: 12},
    lastName: {xs: 12, md: 12},
    document: {xs: 12, md: 12},
    gender: {xs: 12, md: 12, "ui:widget": "radio", fullWidth: true},
    birthDate: {xs: 24, md: 12},
    phone: {xs: 24, md: 12},
};

export function getAmount(key: string, allStats: DTFullEndUser['stats']) {
    const stat = allStats.filter(s => s.kind === key).shift();
    return stat ? formatMoney(stat.count) : '0';
}

export function LastGroupRender({group}: { group: LastGroupDTO | null }) {
    if (group == null) {
        return <>Sin grupo según filtros</>;
    }

    if (group.end_date) {
        return <>{group.name} (Finalizo el {formatDateTime(group.end_date)}</>
    }

    return <>{group.name}</>
}

export function GoalsWithProgress({data}: { data: GroupDTO }) {

    const goals = map(data.total_shares_bought * data.share_price, data.share_price, data.goals);

    return <div>
        <Descriptions column={1} bordered>
            {goals.map(g => <Descriptions.Item label={`${g.description} (${g.category})`} key={g.id}>
                    {formatMoney(g.progress)}% de {formatMoney(g.required)}
                </Descriptions.Item>
            )}
        </Descriptions>
    </div>
}


type Goal = GoalDTO & {
    progress: number;
    missingShares: number;
}

function map(money: number, pricePerShare: number, goals: Array<GoalDTO>): Array<Goal> {

    let left = money
    const source = [...goals].sort((g1, g2) => g1.id - g2.id)
    const toRet: Array<Goal> = []
    for (const g of source) {
        const moneyRequired = g.required;
        let progress: number;
        let missingShares: number;

        if (left > moneyRequired) {
            // we have enough money
            progress = 100
            left -= moneyRequired
            missingShares = 0
        } else if (left <= 0) {
            // we don't have any money
            progress = 0
            missingShares = moneyRequired / pricePerShare
        } else {

            // we have some money
            progress = (left * 100 / moneyRequired)
            missingShares = (moneyRequired - left) / pricePerShare
            left = 0
        }
        toRet.push({
            id: g.id,
            description: g.description,
            category: g.category,
            required: moneyRequired,
            progress: progress,
            missingShares: missingShares
        });
    }

    return toRet
}


