import {Async, AsyncHelper} from "./Model";
import {Api, ApiError} from "./Api";
import {useCallback, useEffect, useState} from "react";
import {useUser} from "./AuthContext";

export function useApi<K extends keyof Api,
    PARAMS extends Parameters<Api[K]>,
    RTYPE extends ReturnType<Api[K]>>(
    producer: K,
    ...params: PARAMS
): Async<Await<RTYPE>, ApiError> & { refresh: () => void } {

    const [data, setData] = useState<Async<Await<RTYPE>, ApiError>>(AsyncHelper.fetching());
    const {api} = useUser()

    const doFetch = useCallback(() => {
        setData(AsyncHelper.fetching());

        const toCall = api[producer].bind(api);
        if (!isFunction(toCall)) {
            throw new Error('Invalid api function: ' + producer);
        }


        // We should remove the promise/await
        const result: Promise<Await<RTYPE>> = (toCall as any).apply(api, params as any)
        result.then(d => setData(AsyncHelper.loaded(d)))
            .catch(e => setData(AsyncHelper.error(e)))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [api, producer, ...params])

    useEffect(doFetch, [doFetch])

    return {
        ...data,
        refresh: doFetch
    };
}

type Await<T> = T extends {
    then(fulfilled?: (value: infer U) => unknown): unknown;
} ? U : T;

function isFunction(functionToCheck: any) {
    return functionToCheck && (
        {}.toString.call(functionToCheck) === '[object Function]'
        || {}.toString.call(functionToCheck) === '[object AsyncFunction]'
    );
}
