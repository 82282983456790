import {InvalidRequest} from "../Model";
import {t} from "../Messages";

export function extractMsg(validation: InvalidRequest) {

    if (Object.keys(validation.detail).length === 0) return t('invalid.request');

    return Object.keys(validation.detail)
        .flatMap(k => validation.detail[k].map(e => `${t(k)} ${t(e)}`))
        .join(", ");
}
