import React from "react";
import {Space, Spin} from 'antd';
import {useUser} from "../AuthContext";
import {useAsync} from "../hooks/useAsync";

export function AjaxImage(props: {
    src: string,
    alt?: string
}) {
    const {api} = useUser();
    const image = useAsync(
        (src: string) => api.getBlob(src, 'GET').then(bl => URL.createObjectURL(bl)),
        [props.src],
        [api]
    );


    let body: React.ReactNode = null;
    switch (image.state) {
        case 'LOADED':
            body = <img src={image.data}
                        style={{width: '100%'}}
                        alt={props.alt || 'image'}/>
            break;
        case "FETCHING":
        case "NO_REQUESTED":
            body = <Space size="large">
                <Spin size="large"/>
            </Space>
            break;
        case "ERROR":
            body = <div>Error cargando image</div>
            break;
    }

    return <div>{body}</div>;
}
