import {jsPDF, jsPDFOptions} from 'jspdf';
import html2canvas from 'html2canvas';

// https://github.com/johnnywang1994/jsPDF-html2canvas/blob/1d63322f95/src/config.js

export interface Opts {
    imageType: string
    jsPDF: jsPDFOptions

    imageQuality: number,
    margin: {
        left: number,
        right: number,
        top: number,
        bottom: number
    },
    html2canvas: {
        imageTimeout: number,
        logging: boolean,
        useCORS: boolean,
    },
    output: string,
    init: (opts: Opts, pdf: jsPDF) => void
    success: (pdf: jsPDF) => void;
}

export const defaultOpts: Opts = {
    jsPDF: {
        unit: 'px',
        format: 'a4',
    },
    html2canvas: {
        imageTimeout: 15000,
        logging: true,
        useCORS: false,
    },
    margin: {
        right: 0,
        top: 0,
        bottom: 0,
        left: 0,
    },
    imageType: 'image/jpeg',
    imageQuality: 1,
    output: 'jspdf-generate.pdf',
    init: function (opts: Opts, pdf: jsPDF) {
    },
    success: function (pdf: jsPDF) {
        pdf.save(this.output);
    }
}

const images = function (type: string) {
    let types: Record<string, string> = {
        'image/jpeg': 'JPEG',
        'image/png': 'PNG',
        'image/webp': 'WEBP'
    };
    return types[type];
};

// ----- jsPDF -----
function getPdf(opts: Opts) {
    const {margin} = opts;
    const pdf = new jsPDF(opts.jsPDF);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const pdfContentWidth = pdfWidth - (margin.left + margin.right);
    const pdfContentHeight = pdfHeight - (margin.top + margin.bottom);
    const position = 0; // page's start position
    const currentPage = 1; // current page number of total pdf
    const pageOfCurrentNode = 1; // current page of current node
    return {
        pdf,
        pdfWidth,
        pdfHeight,
        pdfContentWidth,
        pdfContentHeight,
        position,
        currentPage,
        pageOfCurrentNode,
    };
}

type PDFInstance = ReturnType<typeof getPdf>;

// canvas to DataUri
function getPageData({canvas, pdf, pdfContentWidth, opts}: {
    canvas: HTMLCanvasElement,
    pdf: jsPDF,
    pdfContentWidth: number,
    opts: Opts
}) {
    const pageData = canvas.toDataURL(opts.imageType, opts.imageQuality);
    const imgProps = pdf.getImageProperties(pageData);
    const imgHeight = pdfContentWidth / imgProps.width * imgProps.height;
    return {
        pageData,
        imgHeight,
    };
}


function onCanvasRendered(canvas: HTMLCanvasElement, pdfInstance: PDFInstance, opts: Opts) {
    let {
        pdf,
        pdfContentWidth,
        pdfContentHeight,
        pdfWidth,
        pdfHeight,
        position,
        currentPage,
        pageOfCurrentNode
    } = pdfInstance;
    const {pageData, imgHeight} = getPageData({canvas, pdf, pdfContentWidth, opts});

    // height which not yet print to PDF.
    let leftHeight = imgHeight;

    // check if need reset position(change node)
    if (position < 0) {
        pdf.addPage();
        currentPage += 1;
        pageOfCurrentNode = 1;
        position = 0;
    }

    // check if content needs multi pages
    const {margin} = opts;
    while (leftHeight > 0) {
        // add content
        pdf.addImage(
            pageData,
            images(opts.imageType),
            margin.left,
            position +
            margin.top * pageOfCurrentNode +
            margin.bottom * (pageOfCurrentNode - 1),
            pdfContentWidth,
            imgHeight,
        );
        // add margin top/bottom
        pdf.setFillColor(255, 255, 255);
        pdf.rect(0, 0, pdfWidth, margin.top, 'F');
        pdf.rect(0, pdfHeight - margin.bottom, pdfWidth, margin.bottom, 'F');
        // check left content
        if (leftHeight < pdfContentHeight) {
            position -= leftHeight;
            break;
        } else {
            leftHeight -= pdfContentHeight;
            position -= pdfHeight;
            pdf.addPage();
            currentPage += 1;
            pageOfCurrentNode += 1;
        }
    }

    // expose for next round
    pdfInstance.pdf = pdf;
    pdfInstance.position = position;
    pdfInstance.currentPage = currentPage;
    pdfInstance.pageOfCurrentNode = pageOfCurrentNode;

    return {pdf, position};
}

export async function html2PDF(dom: HTMLElement | HTMLElement[], opts: Partial<Opts> = defaultOpts) {
    const finalOpts: Opts = {...defaultOpts, ...opts};
    const pdfInstance: PDFInstance = getPdf(finalOpts);
    // init pdf
    finalOpts.init(finalOpts, pdfInstance.pdf);

    // multi pages by nodes
    if (Array.isArray(dom)) {
        for (let i = 0; i < dom.length; i++) {
            const canvas = await html2canvas(dom[i], opts.html2canvas);
            onCanvasRendered(canvas, pdfInstance, finalOpts);
        }
    } else {
        // single page for one node
        const canvas = await html2canvas(dom, opts.html2canvas);
        onCanvasRendered(canvas, pdfInstance, finalOpts);
    }

    finalOpts.success.call(opts, pdfInstance.pdf);

    return pdfInstance.pdf;
}
